import eicarActing from "../assets/formations/eicar-acting.jpg";
import eicarAnim from "../assets/formations/eicar-animation-vfx.jpg";
import eicarBtsAudiovisuel from "../assets/formations/eicar-bts-audiovisuel.jpg";
import eicarBusinessProduction from "../assets/formations/eicar-business-production.jpg";
import eicarCineAudio from "../assets/formations/eicar-cinema-et-audiovisuel.jpg";
import eicarFilmmaking from "../assets/formations/eicar-business-production.jpg";
import eicarIntlFilm from "../assets/formations/eicar-international-filmmaking-programs.jpg";
import eicarIntlProg from "../assets/formations/eicar-international-programs.jpg";
import eicarProdAudio from "../assets/formations/eicar-production-audiovisuelle.jpg";
import eicarSceneSpect from "../assets/formations/eicar-scene-et-spectacle.jpg";
import eicarSonMusique from "../assets/formations/eicar-son-et-musique.jpg";
import eicarSoundMusic from "../assets/formations/eicar-sound-and-music.jpg";

//
import illu from "../assets/formations/ynov-2d-illustration.jpg";
import anim from "../assets/formations/ynov-3d-animation.jpg";
import archi from "../assets/formations/ynov-architecture.jpg";
import audiovisuel from "../assets/formations/ynov-audiovisuel.jpg";
import crea from "../assets/formations/ynov-creation.jpg";
import info from "../assets/formations/ynov-informatique.jpg";
import market from "../assets/formations/ynov-marketing.jpg";
import masteres from "../assets/formations/ynov-masteres.jpg";
import tech from "../assets/formations/ynov-technology.jpg";
import placeholder from "../assets/formations/image-fallback.jpg";
// Logos
import logoAnim from "../assets/brochures/logo_ynov_3d_animation.svg";
import logoArchi from "../assets/brochures/logo_ynov_architecture.svg";
import logoAv from "../assets/brochures/logo_ynov_audiovisuel.svg";
import logoYnov from "../assets/brochures/logo_ynov_campus.svg";
import logoCrea from "../assets/brochures/logo_ynov_creation_et_design.svg";
import logoInfo from "../assets/brochures/logo_ynov_informatique.svg";
import logoMark from "../assets/brochures/logo_ynov_marketing_et_communication.svg";
import logoTech from "../assets/brochures/logo_ynov_tech_business.svg";

const assetsMap: Record<number, string> = {
  39: eicarActing,
  37: eicarAnim,
  46: eicarBtsAudiovisuel,
  40: eicarBusinessProduction,
  44: eicarCineAudio,
  35: eicarFilmmaking,
  48: eicarIntlFilm,
  38: eicarIntlProg,
  49: eicarProdAudio,
  45: eicarSceneSpect,
  47: eicarSonMusique,
  36: eicarSoundMusic,
  29: illu,
  30: anim,
  31: archi,
  16: audiovisuel,
  32: crea,
  12: info,
  22: market,
  17: masteres,
  43: tech,
};

const brochureMaps: Record<number, { url: string; logo: string }> = {
  30: {
    url: "https://www.ynov.com/brochures/brochure_ynov_3dajv_20242025.pdf",
    logo: logoAnim,
  },
  51: {
    url: "https://www.ynov.com/brochures/brochure_ynov_archi_20242025.pdf",
    logo: logoArchi,
  },
  16: {
    url: "https://www.ynov.com/brochures/brochure_ynov_av_20242025.pdf",
    logo: logoAv,
  },
  32: {
    url: "https://www.ynov.com/brochures/brochure_ynov_crea_20242025.pdf",
    logo: logoCrea,
  },
  52: {
    url: "https://www.ynov.com/brochures/brochure_ynov_cybsec_20242025.pdf",
    logo: logoInfo,
  },
  12: {
    url: "https://www.ynov.com/brochures/brochure_ynov_info_20242025.pdf",
    logo: logoInfo,
  },
  50: {
    url: "https://www.ynov.com/brochures/brochure_ynov_market_20242025.pdf",
    logo: logoMark,
  },
  17: {
    url: "https://www.ynov.com/brochures/brochure_ynov_mast_20242025.pdf",
    logo: logoYnov,
  },
  53: {
    url: "https://www.ynov.com/brochures/brochure_ynov_techb_20242025.pdf",
    logo: logoTech,
  },
};

export const getBrochureUrlForTraining = (id: number): string => {
  return brochureMaps[id]?.url || "";
};
export const getBrochureLogoForTraining = (id: number): string => {
  return brochureMaps[id]?.logo || logoYnov;
};

export const brochureExistsForTraining = (id: number): boolean => {
  return Object.keys(brochureMaps).includes(id.toString());
};

const getVisualForTraining = (id: number): string => {
  return assetsMap[id] || placeholder;
};

export default getVisualForTraining;
