import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useData } from "../providers/DataProvider";

const useFormFieldsVisibility = () => {
  const { campus } = useData()!;
  const [params] = useSearchParams();
  const ville = useMemo(() => {
    const campusItem = campus.array.find(
      (campusItem) => parseInt(params.get("campus_id") || "0") === campusItem.id
    );
    return (
      (!params.get("ville_id") && !params.get("campus_id")) ||
      (campusItem && campusItem.villes_ids.length > 1)
    );
  }, [params, campus.array]);
  const formation = useMemo(() => !params.get("formation_id"), [params]);
  const programme = useMemo(() => !params.get("programme_id"), [params]);

  return { ville, formation, programme };
};

export default useFormFieldsVisibility;
