import {
  getBrochureLogoForTraining,
  getBrochureUrlForTraining,
} from "../helpers/assets-map";
import { YnovCandidature } from "../types/DataTypes";
import Lottie from "lottie-react";
import download from "../assets/anims/download.json";

export type BrochureCardProps = {
  candidature: YnovCandidature;
  summary?: boolean;
};

const BrochureCard: React.FC<BrochureCardProps> = ({
  candidature,
  summary,
}) => {
  return (
    <div
      className={`${
        summary ? "mx-3 md:mx-5" : ""
      } grow bg-gray-50 p-2 lg:p-5 my-3 lg:my-6 rounded shadow text-sm`}
    >
      <a
        href={getBrochureUrlForTraining(candidature.formation.id)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center flex-col md:flex-row">
            <div>
              <Lottie animationData={download} />
            </div>
            <div className="md:text-left text-center">
              <h4 className="text-xl">
                Votre brochure{" "}
                <span className="font-bold italic block md:inline">
                  {candidature.formation.nom}
                </span>{" "}
                {!summary && <>est prête !</>}
              </h4>
              {!summary && (
                <p>Vous pouvez la télécharger en cliquant sur ce bandeau </p>
              )}
            </div>
          </div>
          <div className="md:my-0 my-5">
            <img
              className="w-15 h-14"
              src={getBrochureLogoForTraining(candidature.formation.id)}
              alt="Logo Ynov"
            />
          </div>
        </div>
      </a>
    </div>
  );
};

export default BrochureCard;
