import { parsePhoneNumber } from "libphonenumber-js"
import isEmail from "validator/lib/isEmail"

// A validation rule is a function that takes the value of the input to test and returns either true or an error message
export type ValidationRule = (val: any) => string | true

// complex handlers
const isValidPhoneNumber = () => (val: string): string | true => {
    try {
        const parsed = parsePhoneNumber(val)
        return parsed.isValid() || "Veuillez entrer un numéro de téléphone valide"
    } catch (error) {
        return "Veuillez entrer un numéro de téléphone valide"
    }
}


// the rules object contains ValidationRules factories (function that returns validation rules)
export const rules = {
    textMinLength: (length: number) => (val: string) => val.length >= length || "Le texte entré est trop court",
    textMaxLength: (length: number) => (val: string) => val.length <= length || "Le texte entré est trop long",
    email: () => (val: string) => isEmail(val) || "Veuillez entrer un email valide",
    requiredSelect: () => (val: number) => !!val || "Veuillez sélectionner un choix valide",
    isValidPhoneNumber
}