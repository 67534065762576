import { ArrowForwardIos } from "@mui/icons-material";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { getBrochureLogoForTraining } from "../helpers/assets-map";
import { useData } from "../providers/DataProvider";
import { YnovCandidature } from "../types/DataTypes";

export type CandidatureCardProps = {
  candidature: YnovCandidature;
};

const CandidatureCard: React.FC<CandidatureCardProps> = ({ candidature }) => {
  const { candidatureList } = useData()!;
  const getLink = useCallback(() => {
    switch (candidature.etat_id) {
      case 3:
        return `/finalize?tiers_id=${candidatureList.tiers?.id}&candidature_id=${candidature.id}`;
      default:
        return `/candidature?tiers_id=${candidatureList.tiers?.id}&candidature_id=${candidature.id}`;
    }
  }, [candidature, candidatureList.tiers]);

  return (
    <div className="w-full bg-gray-50 p-3 lg:p-6 rounded shadow text-sm">
      <Link to={getLink()}>
        <div className="flex flex-col md:flex-row justify-between font-normal">
          <div className="flex flex-col md:flex-row items-center text-center md:text-left">
            <div className="w-28 mb-3 md:mb-0">
              <img
                src={getBrochureLogoForTraining(candidature.formation.id)}
                alt=""
              />
            </div>
            <div>
              <div className="font-bold">
                {candidature.annee.nom} - Candidature #{candidature.id}
              </div>
              <div>{candidature.programme.nom}</div>
              <div className="italic">{candidature.structure.nom}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center text-center md:text-left md:mt-0 mt-8">
            <div className="italic mr-3">{candidature.etat.nom}</div>
            <div className="rotate-90 md:rotate-0">
              <ArrowForwardIos color="primary" />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CandidatureCard;
