import { AlertColor } from "@mui/material";
import { TrackingContextType } from "../providers/TrackingProvider";

export enum DocumentType {
  ID_PICTURE = "1",
  CV = "2",
  PROOFOFID = "3",
  DIPLOMA = "4",
  SCHOOL_REPORT = "5",
  // Can be extended
}

export type YnovCampus = {
  id: number;
  nom: string;
  villes_ids: number[];
};

export type YnovVille = {
  id: number;
  nom: string;
  public: boolean;
};

export type YnovFormation = {
  id: number;
  nom: string;
  date_creation: string;
  date_modification: string;
  etat_id: number;
  actif_prestation: boolean;
  nom_court: string;
};

export type YnovProgramme = {
  id: number;
  formation_id: number;
  nom: string;
  date_creation: string;
  date_modification: string;
  etat_id: number;
  nom_court: string;
  uniquement_reinscrit: number;
  niveauetude_id: number;
  diplome_id: number | null;
  libelle_court: string;
  non_commercialisable: boolean;
  domaine_id: number;
  formation: YnovFormation;
};

export type IncompleteFormObject = {
  type: string;
  ville_id: number;
  formation_id: number;
  programme_id: number;
  prenom: string;
  nom: string;
  telephone: string;
  email: string;
  adConsent: boolean;
  intensif?: boolean;
} & TrackingContextType;

export type FormObject = IncompleteFormObject & {
  ip: string;
};

export type FormPostResponse = {
  tiers_id: number;
  candidature_id?: number;
  token: string;
};

export type SignatureObject = {
  tiers_id: string;
  salt: string;
  hmac: string;
  candidature_id?: string;
};

export type YnovSimpleTiers = {
  id: number;
  nom: string;
  prenom: string;
  mails: { mail: string }[];
  telephones: { telephone: string }[];
  adresse_1: string;
  ddn: string;
  nationalite: YnovPays;
  vdn: string;
};

export type YnovDocument = {
  type_id: DocumentType;
  nom: string;
  date_creation: string;
};

export type YnovCandidature = {
  id: number;
  tiers_id: number;
  etat_id: number;
  programme_id: number;
  ville_id: number;
  tiers: { prenom: string; nom: string };
  formation: { id: number; nom: string };
  annee: { id: number; nom: string };
  programme: { nom: string };
  structure: { nom: string };
  etat: { id: number; nom: string };
  parcours: YnovParcours[];
  documents: YnovDocument[];
  ville?: { id: number; nom: string };
};

export type YnovParcours = {
  annee?: number;
  etablissement_nom?: string;
  etablissement_adresse?: string;
  niveauetude?: number;
  diplome?: number;
};

type YnovOtherInfos = {
  vdn?: string;
  ddn?: string;
  adresse_1?: string;
  nationalite?: string;
};

export type YnovCandidatureUpdate = {
  parcours?: YnovParcours[];
  infos: YnovOtherInfos;
};

export type YnovFrais = {
  id: number;
  programme_id: number;
  ville_id: number;
  montant: string;
  devise: string;
};

export type YnovFinalizeStatus = {
  status: "payment-pending" | "done";
  payment_url: "string";
};

export type SnackbarObject = {
  color: AlertColor;
  message: string;
  show: boolean;
};

export type DiplomeParcours = {
  id: number;
  nom: string;
  date_creation: string;
  date_modification: string;
  annee_debut_id: number | null;
  annee_fin_id: number | null;
};

export type YnovYear = {
  id: number;
  defaut: boolean;
};

export type YnovInseeCode = {
  id: number;
  insee_code: string;
  city_name: string;
  departement_code: string;
};

export type YnovPays = {
  id_iso2: string;
  nom: string;
  id_iso3: string;
  possede_cp: string;
  prefix_tel: string;
  date_creation: Date;
  date_modification: Date;
  nom_fr: string;
  nom_en: string;
  nom_es: string;
  nom_it: string;
  nom_de: string;
  principal: boolean;
  appartient_ue: boolean;
  href: string;
  est_pays: string;
  nom_locale: string;
};
