import { TrackingContextType } from "../providers/TrackingProvider";

const trackingValues = (tracking: TrackingContextType): Record<string, any> => {
    return Object.entries(tracking)
    .map((value) => ({
      [value[0]]: value[1].current,
    }))
    .reduce((previous, current) => ({
      ...previous,
      ...current,
    }))
}

export default trackingValues