const formatCookie = <CookieObjectType extends Record<string, any> = {}>(): CookieObjectType => {
    const cookieString = document.cookie
    const cookie = cookieString.split("; ").map((str) => ({ [str.split("=")[0]]: str.split("=")[1] })).reduce((previous, item) => {
        return { ...previous, ...item }
    })

    return cookie as CookieObjectType
}


export default formatCookie