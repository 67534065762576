import React, { createContext, useContext, useRef } from "react";
import useInitTracking from "../hooks/init-tracking";

export type TrackingContextType = {
  campagne_id?: React.MutableRefObject<string | undefined>;
  landing_url?: React.MutableRefObject<string | undefined>;
  referer_url?: React.MutableRefObject<string | undefined>;
  date_landing?: React.MutableRefObject<string | undefined>;
};

const TrackingContext = createContext<TrackingContextType>(null as any);
export const useTracking = () => useContext(TrackingContext);

const TrackingProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const campagne_id = useRef<string>();
  const landing_url = useRef<string>();
  const referer_url = useRef<string>();
  const date_landing = useRef<string>();

  useInitTracking({ campagne_id, landing_url, referer_url, date_landing });

  return (
    <TrackingContext.Provider
      value={{
        campagne_id,
        landing_url,
        referer_url,
        date_landing,
      }}
    >
      {children}
      {/* monitored input fields targeted by Ynov register.js */}
      <div className="hidden">
        <form id="register">
          <input
            name="landing_url"
            onChange={(e) => {
              if (e.target.value) landing_url.current = e.target.value;
            }}
          ></input>
          <input
            name="referer_url"
            onChange={(e) => {
              if (e.target.value) referer_url.current = e.target.value;
            }}
          ></input>
          <input
            name="date_landing"
            onChange={(e) => {
              if (e.target.value) date_landing.current = e.target.value;
            }}
          ></input>
        </form>
      </div>
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;
