import { useEffect, useState } from "react"
import { DocumentType } from "../types/DataTypes"
import useNewerDocuments from "./newerDocuments"

const useDisabledDropText = () => {
    const [proofOfIdText, setProofOfIdText] = useState("")
    const [diplomaText, setDiplomaText] = useState("")
    const [schoolReportText, setSchoolReportText] = useState("")
    const newerDocuments = useNewerDocuments()

    useEffect(() => {
        const proofOfId = newerDocuments.find((doc) => doc.type_id as any === parseInt(DocumentType.PROOFOFID, 10))
        const diploma = newerDocuments.find((doc) => doc.type_id as any === parseInt(DocumentType.DIPLOMA, 10))
        const schoolReport = newerDocuments.find((doc) => doc.type_id as any === parseInt(DocumentType.SCHOOL_REPORT, 10))

        if (proofOfId) {
            setProofOfIdText(`Vous avez fourni "${proofOfId.nom}" le ${new Date(proofOfId.date_creation).toLocaleDateString("fr-FR")} à ${new Date(proofOfId.date_creation).toLocaleTimeString("fr-FR")}`)
        } else setProofOfIdText("")
        if (diploma) {
            setDiplomaText(`Vous avez fourni "${diploma.nom}" le ${new Date(diploma.date_creation).toLocaleDateString("fr-FR")} à ${new Date(diploma.date_creation).toLocaleTimeString("fr-FR")}`)
        } else setDiplomaText("")
        if (schoolReport) {
            setSchoolReportText(`Vous avez fourni "${schoolReport.nom}" le ${new Date(schoolReport.date_creation).toLocaleDateString("fr-FR")} à ${new Date(schoolReport.date_creation).toLocaleTimeString("fr-FR")}`)
        } else setSchoolReportText("")

    }, [newerDocuments])

    return { proofOfId: proofOfIdText, diploma: diplomaText, schoolReport: schoolReportText }
}


export default useDisabledDropText