import { CircularProgress } from "@mui/material";

const LoadingCircular: React.FC<{}> = () => (
  <div className="h-screen p-3">
    <div className="flex flex-col items-center">
      <CircularProgress size={96} className="my-10" />
      <div>
        Nous préparons votre dossier, veuilez patienter quelques instants...
      </div>
    </div>
  </div>
);

export default LoadingCircular;
