import { useMemo } from "react"
import { useData } from "../providers/DataProvider"

const useDefaultYear = () => {
    const { years } = useData()!
    const defaultYear = useMemo(() => {
        return years.array.find((year) => year.defaut)
    }, [years.array])

    return defaultYear
}

export default useDefaultYear