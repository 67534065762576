import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import DataProvider from "./providers/DataProvider";
import Theme from "./providers/Theme";
import "./styles/index.css";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-displaynames/locale-data/en"; // locale-data for en
import "@formatjs/intl-displaynames/locale-data/fr"; // locale-data for fr
import "@formatjs/intl-listformat/polyfill";
import "@formatjs/intl-listformat/locale-data/en"; // locale-data for en
import "@formatjs/intl-listformat/locale-data/fr"; // locale-data for en
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en"; // locale-data for en
import "@formatjs/intl-pluralrules/locale-data/fr"; // locale-data for en

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Theme>
        <DataProvider>
          <App />
        </DataProvider>
      </Theme>
    </BrowserRouter>
  </React.StrictMode>
);
