import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ValidationRule } from "../helpers/rules-manager";

type InputProps = {
  value: string;
  onChange: (value: string, info: MuiTelInputInfo) => void;
  placeholder?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  rules?: ValidationRule[];
};
export type InputHandle = {
  validate: () => boolean;
};

const Input: React.ForwardRefRenderFunction<InputHandle, InputProps> = (
  props,
  forwardedRef
) => {
  const validate = useCallback(() => {
    if (props.rules) {
      for (const rule of props.rules) {
        const result = rule(input.current?.value);
        if (!(result === true)) {
          setHelperText(result);
          setRuleError(true);
          return false;
        }
      }
    }
    setHelperText("Numéro de téléphone");
    setRuleError(false);
    return true;
  }, [props.rules]);
  useImperativeHandle(forwardedRef, () => ({
    validate,
  }));
  const [helperText, setHelperText] = useState("Numéro de téléphone");
  const [ruleError, setRuleError] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const lazyValidate = useCallback(() => {
    if (ruleError) {
      // validate only if the error is set
      validate();
    }
  }, [ruleError, validate]);

  return (
    <MuiTelInput
      inputRef={input}
      variant="standard"
      value={props.value}
      helperText={helperText}
      error={ruleError}
      onChange={(e, info) => {
        lazyValidate();
        props.onChange(e, info);
      }}
      onBlur={validate}
      placeholder="Numéro de téléphone"
      preferredCountries={["FR", "BE"]}
      fullWidth
      langOfCountryName="fr"
      defaultCountry="FR"
    />
  );
};

export default forwardRef(Input);
