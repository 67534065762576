import { useCallback, useEffect, useMemo, useState } from "react"

const useStickyFooter = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [bodyHeight, setBodyHeight] = useState(document.body.clientHeight)
    const resizeObserver = useMemo(() => new ResizeObserver(entries =>
        setBodyHeight(entries[0].target.clientHeight)
    ), [])

    useEffect(() => {
        window.onresize = () => { setWindowHeight(window.innerHeight) }
        resizeObserver.observe(document.body)
    }, [resizeObserver])

    const isSticky = useCallback(() => {
        return bodyHeight < windowHeight ? "absolute bottom-0 w-full" : ""
    }, [windowHeight, bodyHeight])
    return isSticky()
}


export default useStickyFooter