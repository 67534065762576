import { useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import formatCookie from "../helpers/format-cookie"

type TrackingInitData = {
    campagne_id: React.MutableRefObject<string | undefined>
    landing_url: React.MutableRefObject<string | undefined>
    referer_url: React.MutableRefObject<string | undefined>
    date_landing: React.MutableRefObject<string | undefined>
}
type TrackingCookie = {
    ck_crm_id_campaign?: string
    trk_landing_url?: string
    trk_referer_url?: string
    trk_landing_date?: string
}

const useInitTracking = ({ campagne_id, landing_url, referer_url, date_landing }: TrackingInitData) => {
    const [params] = useSearchParams()
    const initialized = useRef(false)

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            const cookie = formatCookie<TrackingCookie>()

            // set initial ref values
            // commentaire ynov : Priorité campagne_id : cookie > param get
            campagne_id.current = cookie.ck_crm_id_campaign || params.get("campagne_id") || undefined
            landing_url.current = cookie.trk_landing_url
            referer_url.current = cookie.trk_referer_url
            date_landing.current = cookie.trk_landing_date
        }
    }, [params, campagne_id, date_landing, referer_url, landing_url])
}

export default useInitTracking