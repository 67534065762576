import { AlertColor } from "@mui/material";
import Resizer from "react-image-file-resizer";

const imageMimes = ["image/jpg", "image/jpeg", "image/png"];

const fileMimeCheck = (file: File, mimes: string[]): boolean => {
  return mimes.includes(file.type);
};
const getOriginalDimensionsFromImageFile = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const image = new Image();
    const reader = new FileReader();
    image.onload = () => {
      resolve({ width: image.naturalWidth, height: image.naturalHeight });
    };
    reader.addEventListener("load", () => {
      image.src = reader.result as string;
    });
    reader.readAsDataURL(file);
  });
};

const shrinkBy = (filesize: number, maxSize: number) =>
  Math.sqrt(filesize / maxSize);

const resizer = async (
  file: File,
  bytesTarget: number,
  showSnackbar: (
    color: AlertColor,
    message: string,
    timeout?: number | undefined
  ) => void
): Promise<File> => {
  return new Promise(async (resolve, reject) => {
    let resizedFile = file;
    if (!fileMimeCheck(file, imageMimes)) {
      if (file.size > +process.env.REACT_APP_MAX_FILESIZE!) {
        showSnackbar(
          "error",
          "Veuillez choisir un fichier PDF avec une taille inférieure à 2Mo",
          3000
        );
        reject("Fichier trop volumineux");
      }
      resolve(resizedFile);
    }
    // if (bytesTarget >= file.size) {
    //     return resizedFile
    // }
    const imageDimensions = await getOriginalDimensionsFromImageFile(file);
    if (file.type === "image/png") {
      Resizer.imageFileResizer(
        resizedFile,
        imageDimensions.width,
        imageDimensions.height,
        "jpeg",
        70,
        0,
        (processedFile) => {
          resizedFile = processedFile as File;
          // calculate shrinking ratio
          const shrinkRatio = shrinkBy(resizedFile.size, bytesTarget * 0.9); // add a little bit of leeway
          if (shrinkRatio < 1) {
            resolve(resizedFile);
          }
          Resizer.imageFileResizer(
            resizedFile,
            imageDimensions.width / shrinkRatio,
            imageDimensions.height / shrinkRatio,
            "jpeg",
            70,
            0,
            (finalFile) => {
              resolve(finalFile as File);
            },
            "file"
          );
          // shrink and return the file in callback
        },
        "file"
      );
    } else {
      const shrinkRatio = shrinkBy(resizedFile.size, bytesTarget * 0.9); // add a little bit of leeway
      if (shrinkRatio < 1) {
        resolve(resizedFile);
      }
      Resizer.imageFileResizer(
        resizedFile,
        imageDimensions.width / shrinkRatio,
        imageDimensions.height / shrinkRatio,
        "jpeg",
        70,
        0,
        (finalFile) => {
          resolve(finalFile as File);
        },
        "file"
      );
    }
  });
};

export default resizer;
