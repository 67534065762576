import {
  AccountBalance,
  HistoryEduOutlined,
  MailOutline,
  PersonOutline,
  Phone,
  SchoolOutlined,
} from "@mui/icons-material";
import PublicIcon from "@mui/icons-material/Public";
import {
  Autocomplete,
  debounce,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Drop from "../components/Drop";
import Input from "../components/Input";
import Select from "../components/Select";
import { fakeNiveau } from "../helpers/fakedata";
import { getFormattedPhoneNumber } from "../helpers/formatters";
import resizer from "../helpers/resizer";
import { useData } from "../providers/DataProvider";
import { LoadingButton } from "@mui/lab";
import { DocumentType, YnovCandidatureUpdate } from "../types/DataTypes";
import getSchools, { DataGouvSchool } from "../helpers/get-schools";
import getVisualForTraining, {
  brochureExistsForTraining,
} from "../helpers/assets-map";
import useDisabledDropText from "../hooks/disabledDropText";
import BrochureCard from "../components/Brochure";
import LoadingCircular from "../components/LoadingCircular";
import ErrorMessage from "../components/ErrorMessage";
import useDefaultYear from "../hooks/default-year";

type ApplyProps = {};

const Apply: React.FC<ApplyProps> = () => {
  const init = useRef(false);
  const candidatureInit = useRef(false);
  const schoolInFocus = useRef(false);
  const saveInterval = useRef<number | NodeJS.Timer>(0);
  const { apply, signature, years, layout, inseeCode, pays } = useData()!;
  const [loading, setLoading] = useState(false);
  const [validData, setValidData] = useState(false);
  const [error, setError] = useState(false);
  const [schoolListQuery, setSchoolListQuery] = useState("");
  const [schoolInit, setSchoolInit] = useState<DataGouvSchool>();
  const [schoolList, setSchoolList] = useState<DataGouvSchool[]>([]);
  const [schoolListLoading, setSchoolListLoading] = useState(false);
  const [pickedSchool, setPickedSchool] = useState<DataGouvSchool | string>();
  const [lastDiploma, setLastDiploma] = useState(0);
  const [lastEducationLevel, setLastEducationLevel] = useState(0);
  const [proofOfId, setProofOfId] = useState<File>();
  const [diplomaFile, setDiplomaFile] = useState<File>();
  const [gradesFile, setGradesFile] = useState<File>();
  const disabledDropText = useDisabledDropText();
  const defaultYear = useDefaultYear();
  const [ddn, setDdn] = useState("");
  const [vdn, setVdn] = useState("");
  const [adresse_1, setAdresse_1] = useState("");
  const [nationalite, setNationalite] = useState("");
  const [inseeCodeLoading, setInseeCodeLoading] = useState(false);
  const [paysLoading, setPaysLoading] = useState(false);
  const [vdnChanged, setVdnChanged] = useState(false);
  const [vdnFocused, setVdnFocused] = useState(false);
  const [ddnChanged, setDdnChanged] = useState(false);
  const [ddnFocused, setDdnFocused] = useState(false);
  const [nationaliteChanged, setNationaliteChanged] = useState(false);
  const [nationaliteFocused, setNationaliteFocused] = useState(false);
  const [adresse_1Changed, setAdresse_1Changed] = useState(false);
  const [adresse_1Focused, setAdresse_1Focused] = useState(false);

  const formHasChanged = useMemo(
    () =>
      !!vdnChanged ||
      !!ddnChanged ||
      !!nationaliteChanged ||
      !!adresse_1Changed ||
      !!pickedSchool,
    [adresse_1Changed, ddnChanged, nationaliteChanged, pickedSchool, vdnChanged]
  );

  // Redirect on error
  useEffect(() => {
    if (error) window.location.href = "/";
  }, [error]);

  useEffect(() => {
    const initialize = async () => {
      const validData = await apply.getInfos();
      years.get();
      setValidData(validData);
      setError(!validData);
    };

    if (!init.current) {
      init.current = true;
      initialize();
    }
  }, [apply, years]);

  useEffect(() => {
    // If candidature is not in a 0 state, and signature is weak
    // Initializing candidature
    if (apply.candidature && !candidatureInit.current) {
      // if parcours.length, we fill what we can
      if (apply.candidature.parcours.length) {
        setLastEducationLevel(apply.candidature.parcours[0].niveauetude || 0);
        setSchoolInit({
          datasetid: "",
          recordid: "",
          fields: {
            nom_commune: "Choix Enregistré",
            nom_etablissement:
              apply.candidature.parcours[0].etablissement_nom || "",
            adresse_1: "",
            code_nature: 0,
            code_postal: "",
          },
        });
        setLastDiploma(apply.candidature.parcours[0].diplome || 0);
      }
    }
  }, [apply.candidature, apply.tiers, signature]);

  const handleCandidatureUpdate = useCallback(async () => {
    try {
      if (apply.candidature && apply.tiers && formHasChanged) {
        const data: YnovCandidatureUpdate = {
          parcours: [
            ...(pickedSchool
              ? [
                  {
                    annee: apply.candidature.annee.id,
                    etablissement_nom:
                      typeof pickedSchool === "string"
                        ? pickedSchool
                        : pickedSchool.fields.nom_etablissement,
                    etablissement_adresse:
                      typeof pickedSchool === "string"
                        ? pickedSchool
                        : `${pickedSchool.fields.adresse_1}${
                            pickedSchool.fields.adresse_2 || ""
                          }, ${pickedSchool.fields.code_postal} ${
                            pickedSchool.fields.nom_commune
                          }`,
                    niveauetude: lastEducationLevel || 1,
                    ...(lastDiploma ? { diplome: lastDiploma } : {}),
                  },
                ]
              : []),
          ],
          infos: {
            ...(vdnChanged ? { vdn } : {}),
            ...(ddnChanged ? { ddn } : {}),
            ...(adresse_1Changed ? { adresse_1 } : {}),
            ...(nationaliteChanged ? { nationalite } : {}),
          },
        };
        await apply.updateCandidature(
          apply.tiers.id,
          apply.candidature.id,
          data
        );
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [
    apply,
    formHasChanged,
    pickedSchool,
    lastEducationLevel,
    lastDiploma,
    vdnChanged,
    vdn,
    ddnChanged,
    ddn,
    adresse_1Changed,
    adresse_1,
    nationaliteChanged,
    nationalite,
  ]);

  const handleEicarValidation = useCallback((): boolean => {
    if (!apply.frais?.montant) return true;
    return (
      !!gradesFile ||
      !!diplomaFile ||
      !!disabledDropText.diploma ||
      !!disabledDropText.schoolReport
    );
  }, [apply.frais, disabledDropText, gradesFile, diplomaFile]);

  const handleCandidatureValidation = useCallback(async () => {
    try {
      setLoading(true);
      if (!apply.tiers || !apply.candidature) throw new Error("bad request");
      if (!handleEicarValidation()) {
        layout.showSnackbar(
          "error",
          "Veuillez fournir votre dernier diplôme ou dernier bulletin de notes"
        );
        throw new Error("missing_files");
      }
      if (proofOfId) {
        await apply.uploadDocument(
          proofOfId,
          DocumentType.PROOFOFID,
          apply.tiers.id,
          apply.candidature.id
        );
      }
      if (diplomaFile) {
        await apply.uploadDocument(
          diplomaFile,
          DocumentType.DIPLOMA,
          apply.tiers.id,
          apply.candidature.id
        );
      }
      if (gradesFile) {
        await apply.uploadDocument(
          gradesFile,
          DocumentType.SCHOOL_REPORT,
          apply.tiers.id,
          apply.candidature.id
        );
      }
      await handleCandidatureUpdate();

      // Redirect to validation page
      setTimeout(() => {
        if (apply.tiers && apply.candidature) {
          setLoading(false);
          window.parent.location = `${process.env.REACT_APP_FRONT_HOST}/finalize?tiers_id=${apply.tiers.id}&candidature_id=${apply.candidature.id}`;
        }
      }, 1000);
    } catch (error) {
      console.error(error);
      if (error instanceof Error && error.message !== "missing_files") {
        layout.showSnackbar(
          "error",
          "Quelque chose s'est mal passé, veuillez contacter le support Ynov à l'adresse 'support@ynov.com'",
          60000
        );
      }
      setLoading(false);
    }
    // TO DO VALIDATION
  }, [
    apply,
    diplomaFile,
    gradesFile,
    proofOfId,
    handleCandidatureUpdate,
    handleEicarValidation,
    layout,
  ]);

  // TO UNCOMMENT !!!!

  useEffect(() => {
    if (!saveInterval.current) {
      saveInterval.current = setInterval(() => {
        handleCandidatureUpdate();
      }, 30000);
    }

    return () => {
      if (saveInterval.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(saveInterval.current);
        saveInterval.current = 0;
      }
    };
  }, [handleCandidatureUpdate]);

  // School list API
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSchoolQuery = useCallback(
    debounce(async (input: string) => {
      try {
        if (input) {
          const response = (await getSchools(input)).records;
          setSchoolList(response);
        } else {
          setSchoolList([]);
        }
      } catch (error) {
        throw error;
      } finally {
        setSchoolListLoading(false);
      }
    }, 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInseeCodesQuery = useCallback(
    debounce(async (input: string) => {
      try {
        setInseeCodeLoading(true);
        await inseeCode.get(input);
      } catch (error) {
        throw error;
      } finally {
        setInseeCodeLoading(false);
      }
    }, 300),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePaysQuery = useCallback(
    debounce(async (input: string) => {
      try {
        setPaysLoading(true);
        await pays.get(input);
      } catch (error) {
        throw error;
      } finally {
        setPaysLoading(false);
      }
    }, 300),
    []
  );

  const blurSchool = useCallback(() => {
    schoolInFocus.current = false;
  }, []);
  const focusSchool = useCallback(() => {
    schoolInFocus.current = true;
  }, []);
  const getNoOptionsText = useCallback(() => {
    if (schoolListLoading) return "Recherche en cours...";
    if (!schoolListLoading && schoolList.length) return "Recherche en cours...";
    if (!schoolListQuery) return "Entrez quelques caractères...";
    if (!inseeCodeLoading) return "Entrez quelques caractères...";
    if (!paysLoading) return "Entrez quelques caractères...";
    return "Pas de résultat";
  }, [
    schoolList.length,
    schoolListLoading,
    schoolListQuery,
    inseeCodeLoading,
    paysLoading,
  ]);
  // School list API

  // Documents completion methods
  const disableDropzone = useCallback(
    (documentType: DocumentType) => {
      if (apply.candidature) {
        return !!apply.candidature.documents.find(
          (doc) => doc.type_id === (parseInt(documentType, 10) as any)
        );
      } else return false;
    },
    [apply.candidature]
  );

  return (
    <>
      {(validData && apply.tiers && apply.candidature && (
        <>
          {apply.candidatureType === "brochure" &&
            brochureExistsForTraining(apply.candidature.formation.id) && (
              <BrochureCard candidature={apply.candidature} />
            )}
          <div className="flex w-full justify-center xl:justify-start mb-10">
            <div className="py-10 px-3 flex flex-col items-center w-full xl:w-1/2 mr-2">
              <h2 className="w-full text-center xl:text-left text-2xl md:text-3xl font-medium title-underlined">
                {(apply.candidature.etat_id === 0 &&
                  "Je candidate dès maintenant !") ||
                  "Je complète ma candidature"}
              </h2>
              <div className="my-10">
                <h3 className="text-xl title-underlined">Ma candidature</h3>
                <Stack spacing={1} className="ml-4 py-6">
                  <div>
                    <span className="font-bold">Ville : </span>
                    {apply.candidature.ville?.nom}
                  </div>
                  <div>
                    <span className="font-bold">Formation : </span>
                    {apply.candidature.formation.nom}
                  </div>
                  <div>
                    <span className="font-bold">Programme : </span>
                    {apply.candidature.programme.nom}
                  </div>
                </Stack>
                <h3 className="text-xl title-underlined">Mes informations</h3>
                <Stack spacing={3} className="ml-4 py-6">
                  <Input
                    disabled
                    placeholder="Prénom"
                    value={apply.form?.prenom || apply.tiers?.prenom}
                    onChange={() => {}}
                    icon={<PersonOutline />}
                  />
                  <Input
                    disabled
                    placeholder="Nom"
                    value={apply.form?.nom || apply.tiers?.nom}
                    onChange={() => {}}
                    icon={<PersonOutline />}
                  />
                  <Input
                    disabled
                    placeholder="Téléphone"
                    value={
                      getFormattedPhoneNumber(apply.form?.telephone!) ||
                      getFormattedPhoneNumber(
                        apply.tiers?.telephones[0]?.telephone
                      )
                    }
                    onChange={() => {}}
                    icon={<Phone />}
                  />
                  <Input
                    disabled
                    placeholder="E-mail"
                    value={apply.tiers?.mails[0]?.mail}
                    onChange={() => {}}
                    icon={<MailOutline />}
                  />
                  <TextField
                    id="date"
                    label={
                      apply.tiers?.ddn
                        ? `Date de naissance : ${apply.tiers.ddn}`
                        : "Date de naissance"
                    }
                    type="date"
                    value={apply.tiers?.ddn}
                    sx={{ width: 200, height: 50 }}
                    onFocus={() => {
                      setDdnFocused(true);
                    }}
                    onChange={(e) => {
                      if (ddnFocused) {
                        setDdnChanged(true);
                      }
                      setDdn(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                  />
                  <Autocomplete
                    freeSolo
                    onFocus={() => {
                      setVdnFocused(true);
                    }}
                    options={inseeCode.inseeCode}
                    autoComplete={false}
                    getOptionLabel={(option) => {
                      if (typeof option === "object") {
                        return `${option.city_name} (${option.departement_code})`;
                      } else {
                        return option;
                      }
                    }}
                    filterOptions={(options) => options}
                    noOptionsText={getNoOptionsText()}
                    onChange={(e, value) => {
                      if (value) {
                        if (vdnFocused) {
                          setVdnChanged(true);
                        }
                        if (typeof value === "string") {
                          setVdn(value);
                        } else {
                          setVdn(value.city_name);
                        }
                      }
                    }}
                    onInputChange={(e) => {
                      if (vdnFocused) {
                        setVdnChanged(true);
                      }
                      if (e && e.nativeEvent) {
                        setVdn(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        );
                        handleInseeCodesQuery(
                          (e.nativeEvent.target as HTMLInputElement).value
                        );
                      }
                    }}
                    renderInput={(params) => {
                      params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                          <AccountBalance />
                        </InputAdornment>
                      );
                      return (
                        <TextField
                          {...params}
                          placeholder={
                            apply.tiers?.vdn
                              ? `Ville de naissance : ${apply.tiers?.vdn}`
                              : "Ville de naissance"
                          }
                          variant="standard"
                        />
                      );
                    }}
                  />
                  <Input
                    placeholder="Adresse postale"
                    value={
                      adresse_1Changed
                        ? adresse_1
                        : typeof apply.tiers.adresse_1 === "string"
                        ? apply.tiers.adresse_1
                        : ""
                    }
                    onChange={(e) => {
                      if (adresse_1Focused) {
                        setAdresse_1Changed(true);
                      }
                      setAdresse_1(e.target.value);
                    }}
                    onFocus={() => {
                      setAdresse_1Focused(true);
                    }}
                    icon={<PersonOutline />}
                  />
                  <Autocomplete
                    options={pays.pays}
                    autoComplete={false}
                    getOptionLabel={(option) => {
                      if (typeof option === "object") {
                        return `${option.nom} (${option.id_iso2})`;
                      } else {
                        return option;
                      }
                    }}
                    filterOptions={(options) => options}
                    noOptionsText={getNoOptionsText()}
                    onFocus={() => {
                      setNationaliteFocused(true);
                    }}
                    onChange={(e, value) => {
                      if (nationaliteFocused) {
                        setNationaliteChanged(true);
                      }
                      if (value) {
                        if (typeof value === "string") {
                          setNationalite(value);
                        } else {
                          setNationalite(value.id_iso2);
                        }
                      }
                    }}
                    onInputChange={(e) => {
                      if (nationaliteFocused) {
                        setNationaliteChanged(true);
                      }
                      if (e && e.nativeEvent) {
                        setNationalite(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        );
                        handlePaysQuery(
                          (e.nativeEvent.target as HTMLInputElement).value
                        );
                      }
                    }}
                    renderInput={(params) => {
                      params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                          <PublicIcon />
                        </InputAdornment>
                      );
                      return (
                        <TextField
                          {...params}
                          placeholder={
                            typeof apply.tiers?.nationalite === "object" &&
                            apply.tiers?.nationalite
                              ? "Nationalité : " + apply.tiers.nationalite?.nom
                              : typeof apply.tiers?.nationalite === "string"
                              ? apply.tiers.nationalite
                              : "Nationalité"
                          }
                          variant="standard"
                        />
                      );
                    }}
                  />
                </Stack>
                <h3 className="text-xl title-underlined">Ma scolarité</h3>
                <Stack spacing={3} className="lg:ml-4 py-6">
                  <Select
                    placeholder="Votre niveau scolaire"
                    data={fakeNiveau.sort((b, a) => b.id - a.id)}
                    value={lastEducationLevel}
                    onChange={(e) =>
                      setLastEducationLevel(parseInt(e.target.value, 10))
                    }
                    icon={<SchoolOutlined />}
                  />
                  <Autocomplete
                    freeSolo
                    options={schoolList}
                    autoComplete={false}
                    defaultValue={schoolInit}
                    getOptionLabel={(option) => {
                      if (option) {
                        if (typeof option === "object") {
                          return `[${option.fields.nom_commune}] ${option.fields.nom_etablissement}`;
                        } else {
                          return option;
                        }
                      } else {
                        return schoolListQuery;
                      }
                    }}
                    filterOptions={(options) => options}
                    onFocus={focusSchool}
                    onBlur={blurSchool}
                    noOptionsText={getNoOptionsText()}
                    onChange={(e, value) => {
                      if (value) {
                        setPickedSchool(value);
                      }
                    }}
                    onInputChange={(e) => {
                      if (e && e.nativeEvent) {
                        setPickedSchool(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        );
                        setSchoolListLoading(true);
                        setSchoolListQuery(
                          (e.nativeEvent.target as HTMLInputElement).value
                        );
                        setSchoolList([]);
                        handleSchoolQuery(
                          (e.nativeEvent.target as HTMLInputElement).value
                        );
                      }
                    }}
                    renderInput={(params) => {
                      params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                          <AccountBalance />
                        </InputAdornment>
                      );
                      return (
                        <TextField
                          {...params}
                          placeholder="Votre dernier établissement"
                          variant="standard"
                        />
                      );
                    }}
                  />
                  <Select
                    data={apply.diplomes.filter((diplome) => {
                      return defaultYear && diplome.annee_fin_id
                        ? diplome.annee_fin_id >= defaultYear.id
                        : true;
                    })}
                    value={lastDiploma}
                    onChange={(e) => {
                      setLastDiploma(parseInt(e.target.value, 10));
                    }}
                    placeholder="Votre dernier diplôme obtenu"
                    icon={<HistoryEduOutlined />}
                  />
                </Stack>
                <h3 className="text-xl title-underlined">Mes documents</h3>
                <p className="pt-6 text-slate-500">
                  Si je le souhaite, je prends de l'avance en chargeant dès
                  maintenant mes documents nécessaires à la validation de ma
                  candidature
                </p>
                <Stack spacing={3} className="lg:ml-4 py-6">
                  <Drop
                    disabledText={disabledDropText.proofOfId}
                    disabled={disableDropzone(DocumentType.PROOFOFID)}
                    title="Pièce d'identité"
                    file={proofOfId}
                    onDrop={async (acceptedFiles, fileRejections) => {
                      try {
                        if (acceptedFiles.length) {
                          setProofOfId(
                            await resizer(
                              acceptedFiles[0],
                              parseInt(
                                process.env.REACT_APP_MAX_FILESIZE || "0",
                                10
                              ),
                              layout.showSnackbar
                            )
                          );
                        }
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    setFile={setProofOfId}
                  />
                  <Drop
                    disabledText={disabledDropText.diploma}
                    disabled={disableDropzone(DocumentType.DIPLOMA)}
                    title="Dernier diplôme"
                    file={diplomaFile}
                    onDrop={async (acceptedFiles) => {
                      try {
                        if (acceptedFiles.length) {
                          setDiplomaFile(
                            await resizer(
                              acceptedFiles[0],
                              parseInt(
                                process.env.REACT_APP_MAX_FILESIZE || "0",
                                10
                              ),
                              layout.showSnackbar
                            )
                          );
                        }
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    setFile={setDiplomaFile}
                  />
                  <Drop
                    disabledText={disabledDropText.schoolReport}
                    disabled={disableDropzone(DocumentType.SCHOOL_REPORT)}
                    title="Dernier bulletin de notes"
                    file={gradesFile}
                    onDrop={async (acceptedFiles) => {
                      try {
                        if (acceptedFiles.length) {
                          setGradesFile(
                            await resizer(
                              acceptedFiles[0],
                              parseInt(
                                process.env.REACT_APP_MAX_FILESIZE || "0",
                                10
                              ),
                              layout.showSnackbar
                            )
                          );
                        }
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    setFile={setGradesFile}
                  />
                </Stack>
                <div className="text-center">
                  <LoadingButton
                    onClick={handleCandidatureValidation}
                    loading={loading}
                    variant="contained"
                    size="large"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    J'enregistre
                  </LoadingButton>
                </div>
                {apply.frais?.id && (
                  <div className="text-center mt-3 italic">
                    Des frais de dossier de{" "}
                    <span className="font-bold">
                      {`${apply.frais.montant} ${apply.frais.devise} `}
                    </span>
                    s'appliquent. Après validation, vous serez redirigé vers la
                    page de paiement sécurisé
                  </div>
                )}
              </div>
            </div>
            <div
              className="w-1/2 hidden xl:flex justify-center items-center bg-no-repeat"
              style={{
                backgroundImage: `url(${getVisualForTraining(
                  apply.candidature.formation.id
                )})`,
                backgroundSize: "contain",
              }}
            ></div>
          </div>
        </>
      )) ||
        (!error && <LoadingCircular />) || <ErrorMessage />}
    </>
  );
};

export default Apply;
