import { CircularProgress, IconButton, Typography } from "@mui/material";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import prettyBytes from "pretty-bytes";
import { Check, Delete, Edit } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";

export type DropProps = {
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  file?: File;
  setFile?: React.Dispatch<React.SetStateAction<File | undefined>>;
  loading?: boolean;
  title: string;
  disabled?: boolean;
  disabledText?: string;
};

const acceptedMimes = {
  "image/png": [".png"],
  "image/jpg": [".jpg", ".jpeg"],
  "application/pdf": [".pdf"],
};

const Drop: React.FC<DropProps> = ({
  onDrop,
  file,
  title,
  loading,
  setFile,
  disabled,
  disabledText,
}) => {
  const [preview, setPreview] = useState("");
  const fileReader = useMemo(() => new FileReader(), []);
  fileReader.onload = useCallback(() => {
    setPreview(fileReader.result as string);
  }, [fileReader.result]);

  useEffect(() => {
    if (file) {
      fileReader.readAsDataURL(file);
    }
  }, [file, fileReader]);

  return (
    <Dropzone
      disabled={disabled}
      onDrop={onDrop}
      accept={acceptedMimes}
      multiple={false}
      maxSize={30971500}
    >
      {({ getRootProps, getInputProps, isDragActive, rootRef, open }) => (
        <div
          {...getRootProps()}
          style={{
            boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            position: "relative",
          }}
          className={`p-4 rounded-lg text-center ${
            !disabled ? "cursor-pointer" : ""
          }`}
        >
          <input {...getInputProps()} />
          <div className="font-bold">
            {title} {disabled && "✅"}
          </div>
          {loading && (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              className="backdrop-blur-sm bg-white/30"
            >
              <div style={{ color: "white" }}>
                <CircularProgress color="inherit" />
              </div>
              <Typography
                style={{ padding: 10, textAlign: "center" }}
                fontWeight={"bold"}
              >
                Chargement de votre document en cours ⏳
              </Typography>
            </div>
          )}
          {file ? (
            <div className="flex items-center justify-center p-2 flex-col sm:flex-row">
              <img
                src={preview}
                className="max-w-[150px] max-h-[100px] mr-3"
                alt="Prévisualisation du document saisi"
              />
              <div className="mr-2">
                <span>
                  <Check color="success" />
                </span>
                Fichier: {file.name}, taille{" "}
                {prettyBytes(file.size, { locale: "fr" })}
              </div>
              <div>
                {setFile && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      open();
                    }}
                  >
                    <Edit color="info" />
                  </IconButton>
                )}
                {setFile && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFile(undefined);
                    }}
                  >
                    <Delete color="secondary" />
                  </IconButton>
                )}
              </div>
            </div>
          ) : (
            <div className={`p-5 ${disabled ? "text-gray-500" : ""}`}>
              {disabledText ||
                "Faites glisser ou cliquez dans la zone pour charger un document"}
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default Drop;
