import { Paper } from "@mui/material";
import React from "react";

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <Paper
      className="p-10 text-center font-bold"
      square
      sx={{ backgroundColor: "secondary.main", color: "white" }}
    >
      © YNOV {new Date().getFullYear()} – Établissements d’enseignement
      supérieur technique privé hors contrat — informations non contractuelles
    </Paper>
  );
};

export default Footer;
