import Lottie from "lottie-react";
import errorAnim from "../assets/anims/error.json";

const ErrorMessage: React.FC<{}> = () => (
  <div className="h-screen p-3">
    <div className="flex flex-col items-center text-center md:text-left">
      <Lottie
        animationData={errorAnim}
        loop={false}
        style={{ height: "25vmin" }}
      />
      <div>
        Vous n'avez pas la permission d'accéder à cette page, veuillez utiliser
        le lien dans le mail qui vous a été envoyé
      </div>
    </div>
  </div>
);

export default ErrorMessage;
