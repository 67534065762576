import { useCallback } from "react";
import { useData } from "../providers/DataProvider";
import { YnovDocument } from "../types/DataTypes";

const useNewerDocuments = (): YnovDocument[] => {
    const { apply } = useData()!

    const getNewerDocs = useCallback(() => {
        const groupedByType: Record<string, YnovDocument[]> = {};
        const buffer: YnovDocument[] = [];
        if (apply.candidature) {
            for (const document of apply.candidature.documents) {
                if (!groupedByType[document.type_id]) {
                    groupedByType[document.type_id] = [document];
                } else {
                    groupedByType[document.type_id].push(document);
                }
            }
        }
        for (const documentGroup in groupedByType) {
            groupedByType[documentGroup] = groupedByType[documentGroup].sort((a, b) =>
                b.date_creation.localeCompare(a.date_creation)
            );
            buffer.push(groupedByType[documentGroup][0]);
        }
        return buffer
    }, [apply.candidature])

    return getNewerDocs();
}

export default useNewerDocuments