import React, { useEffect, useRef, useState } from "react";
import { useData } from "../providers/DataProvider";
import { Stack } from "@mui/system";
import CandidatureCard from "../components/CandidatureCard";
import ErrorMessage from "../components/ErrorMessage";
import LoadingCircular from "../components/LoadingCircular";
import { Navigate } from "react-router-dom";

type CandidatureListProps = {};

const CandidatureList: React.FC<CandidatureListProps> = () => {
  const init = useRef(false);
  const { candidatureList } = useData()!;
  const [validData, setValidData] = useState(false);
  const [error, setError] = useState(false);
  // Redirect on error
  useEffect(() => {
    if (error) window.location.href = "/";
  }, [error]);

  useEffect(() => {
    const initialize = async () => {
      const validData = await candidatureList.getInfos();
      setValidData(validData);
      setError(!validData);
    };

    if (!init.current) {
      init.current = true;
      initialize();
    }
  }, [candidatureList]);

  return (
    <>
      {(validData && candidatureList.tiers && candidatureList.candidatures && (
        <>
          {candidatureList.candidatures.length === 1 ? (
            <Navigate
              to={`/candidature?tiers_id=${candidatureList.tiers.id}&candidature_id=${candidatureList.candidatures[0].id}`}
            />
          ) : (
            <div className="flex w-full justify-center mb-10">
              <div className="py-10 px-3 flex flex-col items-center w-full xl:w-2/3 mr-2">
                <h2 className="w-full text-center xl:text-left text-2xl md:text-3xl font-medium title-underlined">
                  Bonjour {candidatureList.tiers.prenom}!
                </h2>
                <div className="my-10 w-full">
                  <div className="mt-10 w-full">
                    <Stack spacing={3}>
                      {candidatureList.candidatures.map((candidature) => (
                        <CandidatureCard
                          key={candidature.id}
                          candidature={candidature}
                        />
                      ))}
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )) ||
        (!error && <LoadingCircular />) || <ErrorMessage />}
    </>
  );
};

export default CandidatureList;
