/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useData } from "../providers/DataProvider";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import validatedAnim from "../assets/anims/validated.json";
import { Stack } from "@mui/system";
import { DocumentType } from "../types/DataTypes";
import { getDocumentTypeName } from "../helpers/formatters";
import getVisualForTraining, {
  brochureExistsForTraining,
} from "../helpers/assets-map";
import useNewerDocuments from "../hooks/newerDocuments";
import LoadingCircular from "../components/LoadingCircular";
import ErrorMessage from "../components/ErrorMessage";
import BrochureCard from "../components/Brochure";

type FinalizeProps = {};

const Finalize: React.FC<FinalizeProps> = () => {
  const { finalize, apply, trySignature } = useData()!;
  const initialized = useRef(false);
  const lottie = useRef<LottieRefCurrentProps>(null);
  const [accessError, setAccessError] = useState(false);
  const [validWithoutSign, setValidWithoutSign] = useState(false);
  const newerDocuments = useNewerDocuments();

  useEffect(() => {
    const isValidSignature = async () => {
      setAccessError(!(await apply.getInfos()));
    };

    if (trySignature()) isValidSignature();
    if (finalize.finalizeStatus?.status === "done" && !trySignature()) {
      setValidWithoutSign(true);
    }
  }, [finalize.finalizeStatus, apply.getInfos, trySignature]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      finalize.getFinalizeStatus();
    }
    if (
      apply.candidature &&
      localStorage.getItem(`ysw-anim-${apply.candidature.id}`)
    ) {
      const finalFrame = lottie.current?.getDuration(true);
      if (finalFrame && lottie.current) {
        lottie.current.goToAndStop(finalFrame, true);
      }
    }
  }, [finalize, apply]);

  const getTitle = useCallback(() => {
    if (finalize.finalizeStatus) {
      switch (finalize.finalizeStatus.status) {
        case "payment-pending":
          return "Paiement des frais de dossier";
        case "done":
          return "Félicitations! Votre candidature est prise en compte!";
      }
    }
    return "";
  }, [finalize]);

  const handleAnimationComplete = useCallback(() => {
    if (apply.candidature) {
      localStorage.setItem(`ysw-anim-${apply.candidature.id}`, "true");
    }
  }, [apply.candidature]);

  useEffect(() => {
    if (finalize.finalizeStatus && finalize.finalizeStatus.status === "done") {
      apply.getInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalize.finalizeStatus]);

  return (
    <div className="md:py-10 px-2 md:px-3 flex flex-col items-center">
      {(!accessError && (
        <>
          {(finalize.finalizeStatus && (
            <div
              className={`flex w-full justify-center ${
                apply.candidature ? "xl:justify-start" : ""
              }`}
            >
              <div className="w-full xl:w-1/2">
                <div
                  className={`${!apply.candidature && "justify-center flex"}`}
                >
                  <h2 className="text-2xl md:test-3xl font-medium title-underlined my-3 md:my-10">
                    {getTitle()}
                  </h2>
                </div>
                {(finalize.finalizeStatus.status === "done" && (
                  <>
                    <div
                      className={`flex flex-col items-center text-center md:text-left`}
                    >
                      <Lottie
                        lottieRef={lottie}
                        onComplete={handleAnimationComplete}
                        animationData={validatedAnim}
                        loop={false}
                        style={{ minHeight: 200, height: "25vmin" }}
                      />
                      <div>
                        Nous allons prendre contact rapidement avec vous afin de
                        faire le point sur votre dossier
                      </div>
                      {!validWithoutSign && (
                        <div className="grow w-full text-left">
                          <h2 className="text-2xl md:text-3xl font-medium title-underlined my-3 md:my-10 ">
                            Résumé
                          </h2>
                          <p className="italic mb-5">
                            Votre candidature en détail
                          </p>
                          <Stack spacing={2}>
                            <div>
                              <span className="font-bold">
                                État de votre candidature :{" "}
                              </span>
                              {apply.candidature?.etat.nom}
                            </div>
                            <div>
                              <span className="font-bold">Campus : </span>
                              {apply.candidature?.structure?.nom ||
                                apply.candidature?.ville?.nom}
                            </div>
                            <div>
                              <span className="font-bold">Formation : </span>
                              {apply.candidature?.formation.nom}
                            </div>
                            <div>
                              <span className="font-bold">Programme : </span>
                              {apply.candidature?.programme.nom}
                            </div>
                            {apply.candidature?.documents.length ? (
                              <div>
                                <div className="font-bold">
                                  Vos documents fournis :{" "}
                                </div>
                                {newerDocuments.map((candidatureDocument) => (
                                  <div key={candidatureDocument.nom}>
                                    <span className="font-medium italic">
                                      {getDocumentTypeName(
                                        candidatureDocument.type_id.toString() as DocumentType
                                      )}{" "}
                                      :{" "}
                                    </span>
                                    <span>{candidatureDocument.nom}</span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </div>
                      )}
                      {apply.candidature &&
                        brochureExistsForTraining(
                          apply.candidature.formation.id
                        ) && (
                          <div className="grow w-full text-left">
                            <h2 className="text-2xl md:text-3xl font-medium title-underlined my-3 md:my-10 ">
                              Plus d'infos ?
                            </h2>
                            <div>
                              Besoin de davantage d'informations ?<br />
                              Vous pouvez télécharger votre brochure{" "}
                              <span className="font-bold italic">
                                {apply.candidature?.formation.nom}
                              </span>{" "}
                              en cliquant sur le lien ci-dessous
                            </div>
                            <BrochureCard
                              summary
                              candidature={apply.candidature}
                            />
                          </div>
                        )}
                    </div>
                  </>
                )) || (
                  <div>
                    <div
                      className={
                        apply.candidature ? "text-justify" : "text-center"
                      }
                    >
                      La validation de votre candidature est soumise au
                      règlement de frais de dossier
                      {apply.frais && (
                        <>
                          {" "}
                          de{" "}
                          <span className="font-bold">
                            {apply.frais?.montant} {apply.frais?.devise}
                          </span>
                        </>
                      )}
                      .
                      <div>
                        Vous pouvez procéder au règlement en utilisant la
                        plateforme de paiement Ynov ci-dessous.
                      </div>
                      Une fois le paiement confirmé, vous serez redirigé vers
                      une page de confirmation
                    </div>
                    <iframe
                      height={800}
                      width="100%"
                      title="Paiement Ynov"
                      src={finalize.finalizeStatus.payment_url}
                    />
                  </div>
                )}
              </div>
              {apply.candidature && (
                <div
                  className="w-1/2 hidden xl:flex justify-center items-center"
                  style={{
                    backgroundImage: `url(${getVisualForTraining(
                      apply.candidature.formation.id
                    )})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              )}
            </div>
          )) || <LoadingCircular />}
        </>
      )) || <ErrorMessage />}
    </div>
  );
};

export default Finalize;
