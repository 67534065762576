import {
  AssignmentOutlined,
  EmailOutlined,
  PersonOutline,
  PlaceOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Input, { InputHandle } from "../components/Input";
import Select, { SelectHandle } from "../components/Select";
import { getButtonTitleFromAction } from "../helpers/formatters";
import { useData } from "../providers/DataProvider";
import "../styles/form.scss";
import { LoadingButton } from "@mui/lab";
import { rules } from "../helpers/rules-manager";
import Tel from "../components/Tel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTracking } from "../providers/TrackingProvider";
import trackingValues from "../helpers/tracking-values";
import useFormFieldsVisibility from "../hooks/form-fields-visibility";
import { YnovVille } from "../types/DataTypes";

type FormProps = {};

const Form: React.FC<FormProps> = () => {
  // Router
  const [searchParams] = useSearchParams();
  const [type, setType] = useState("apply");

  // Data
  const { campus, villes, formations, programmes, form, layout } = useData()!;
  const initialized = useRef(false);
  const userInput = useRef(false); // Synchronous ref needed to override the query params matching behavior

  // Layout
  const [mainLoading, setMainLoading] = useState(false);

  // Inputs
  const [selectedVille, setSelectedVille] = useState(0);
  const [filteredVilles, setFilteredVilles] = useState<YnovVille[]>([]);
  const [selectedCampus, setSelectedCampus] = useState(0);
  const [selectedFormation, setSelectedFormation] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState(0);
  const [checkedContact, setCheckedContact] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const formattedPhone = useRef("");
  const [email, setEmail] = useState("");
  const mainClasses: string = useMemo(
    () => (layout.isIframe ? "" : "container mx-auto lg:m-16 lg:mx-auto"),
    [layout.isIframe]
  );
  const tracking = useTracking();
  const visible = useFormFieldsVisibility();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refs = {
    ville: useRef<SelectHandle>(null),
    formation: useRef<SelectHandle>(null),
    programme: useRef<SelectHandle>(null),
    firstname: useRef<InputHandle>(null),
    lastname: useRef<InputHandle>(null),
    telephone: useRef<InputHandle>(null),
    email: useRef<InputHandle>(null),
  };

  // QUERY PARAMETERS
  useEffect(() => {
    const type = searchParams.get("type");
    if (type && ["apply", "brochure"].includes(type)) {
      setType(type);
    }
  }, [searchParams]);

  const intensif = useMemo(
    () => searchParams.get("intensif") === "true",
    [searchParams]
  );

  // Initialization
  useEffect(() => {
    const initialize = async () => {
      if (!initialized.current) {
        setMainLoading(true);
        initialized.current = true;
        await villes.get();
        await campus.get();
        setTimeout(() => {
          setMainLoading(false);
        }, 200);
      }
    };

    initialize();
  }, [campus, villes]);

  // SELECT useEffects
  useEffect(() => {
    const campus_id = parseInt(searchParams.get("campus_id") || "-1", 10);
    const campusFound = campus.array.find(
      (campusObject) => campus_id === campusObject.id
    );
    if (!userInput.current && campus_id && campus.array.length && campusFound) {
      setSelectedCampus(campus_id);
      if (campusFound.villes_ids.length === 1) {
        setSelectedVille(campusFound.villes_ids[0]);
      }
    }
  }, [campus.array, searchParams]);
  useEffect(() => {
    const ville_id = parseInt(searchParams.get("ville_id") || "-1", 10);
    if (
      !userInput.current &&
      ville_id &&
      villes.sorted.length &&
      villes.sorted.find((villeObject) => ville_id === villeObject.id)
    ) {
      setSelectedVille(ville_id);
    }
    const campusFound = campus.array.find(
      (campusItem) => campusItem?.id === selectedCampus
    );
    const filtered = campusFound
      ? villes.sorted.filter((ville) =>
          campusFound.villes_ids.includes(ville.id)
        )
      : villes.sorted;

    const formation_id = parseInt(searchParams.get("formation_id") || "0", 10);
    if (formation_id) {
      villes.getForTraining(formation_id).then((villes) => {
        const trainingFiltered = filtered.filter((villeToFilter) =>
          villes.find((ville) => ville.id === villeToFilter.id)
        );
        setFilteredVilles(trainingFiltered);
      });
    } else {
      setFilteredVilles(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [villes.sorted, searchParams, campus.array, selectedCampus]);

  useEffect(() => {
    const getFormations = async () => {
      setMainLoading(true);
      await formations.get({ campus_id: selectedCampus });
      setTimeout(() => {
        setMainLoading(false);
      }, 200);
    };
    setSelectedProgram(0);
    setSelectedFormation(0);

    if (selectedCampus) {
      getFormations();
    }
    // not including the whole formation object to prevent request loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampus, formations.get]);
  useEffect(() => {
    const getFormations = async () => {
      setMainLoading(true);
      await formations.get({ ville_id: selectedVille });
      setTimeout(() => {
        setMainLoading(false);
      }, 200);
    };
    setSelectedProgram(0);
    setSelectedFormation(0);

    if (selectedVille) {
      getFormations();
    }
    // not including the whole formation object to prevent request loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVille, formations.get]);

  // SELECT useEffects
  useEffect(() => {
    const formation_id = parseInt(searchParams.get("formation_id") || "0", 10);
    if (
      !userInput.current &&
      formation_id &&
      formations.array.length &&
      formations.array.find(
        (formationObject) => formation_id === formationObject.id
      )
    ) {
      setSelectedFormation(formation_id);
    }
    // we need to filter cities that have this training
  }, [formations.array, searchParams, villes.array]);

  useEffect(() => {
    const getProgrammes = async () => {
      setMainLoading(true);
      await programmes.get(
        { campus_id: selectedCampus, ville_id: selectedVille },
        selectedFormation
      );
      setTimeout(() => {
        setMainLoading(false);
      }, 200);
    };

    if ((selectedCampus || selectedVille) && selectedFormation) {
      getProgrammes();
    }
    // not including the whole programmes object to prevent request loop
    // not including selectedCampus dep to prevent flickering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormation, programmes.get]);

  useEffect(() => {
    const programme_id = parseInt(searchParams.get("programme_id") || "-1", 10);
    if (
      !userInput.current &&
      programme_id &&
      programmes.array.length &&
      programmes.array.find(
        (programmeObject) => programme_id === programmeObject.id
      )
    ) {
      setSelectedProgram(programme_id);
    }
  }, [programmes.array, searchParams]);
  // SELECT useEffects

  // METHODS
  const handleValidation = useCallback((): boolean => {
    let valid = true;
    for (const key in refs as Record<string, React.RefObject<InputHandle>>) {
      valid =
        (refs[key as keyof typeof refs].current?.validate() || false) && valid;
    }
    if (!valid) {
      layout.showSnackbar(
        "error",
        "Veuillez remplir correctement les champs soulignés en rouge"
      );
    }
    return valid;
  }, [layout, refs]);
  const handleFormSubmit = useCallback(async () => {
    if (handleValidation()) {
      try {
        if (!selectedVille)
          throw new Error("campus not found & city incorrect");
        await form.post({
          ville_id: selectedVille,
          formation_id: selectedFormation,
          programme_id: selectedProgram,
          email,
          nom: lastname,
          prenom: firstname,
          adConsent: checkedContact,
          telephone: formattedPhone.current,
          type,
          intensif,
          ...trackingValues(tracking),
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    selectedFormation,
    selectedProgram,
    selectedVille,
    lastname,
    firstname,
    checkedContact,
    type,
    email,
    form,
    tracking,
    intensif,
    handleValidation,
  ]);
  // METHODS

  return (
    <>
      {!layout.isIframe && <Header />}
      <div className={mainClasses}>
        {mainLoading && (
          <div className="absolute inset-0 z-10 flex justify-center pt-10">
            <div
              className="absolute inset-0"
              style={{
                backgroundColor: "#33333380",
              }}
            ></div>
            <div className="text-white">
              <CircularProgress color="inherit" size={128} />
            </div>
          </div>
        )}
        {/*  Loading */}
        <Stack spacing={3}>
          {/* TODO Refacto component (icon, state, data, placeholder) */}
          <Select
            hidden={!visible.ville}
            disabled={!villes.array.length}
            placeholder="Choisissez votre campus"
            value={selectedVille}
            onChange={(e) => setSelectedVille(parseInt(e.target.value, 10))}
            rules={[rules.requiredSelect()]}
            ref={refs.ville}
            data={filteredVilles}
            icon={<PlaceOutlined />}
          />
          <Select
            hidden={!visible.formation}
            disabled={
              !formations.array.length || (!selectedCampus && !selectedVille)
            }
            placeholder="Sélectionnez votre formation"
            value={selectedFormation}
            rules={[rules.requiredSelect()]}
            ref={refs.formation}
            onChange={(e) => setSelectedFormation(parseInt(e.target.value, 10))}
            data={formations.array.sort((a, b) => a.nom.localeCompare(b.nom))}
            icon={<SchoolOutlined />}
          />
          <Select
            hidden={!visible.programme}
            disabled={!programmes.array.length || !selectedFormation}
            placeholder="Sélectionnez votre programme"
            value={selectedProgram}
            rules={[rules.requiredSelect()]}
            ref={refs.programme}
            onChange={(e) => setSelectedProgram(parseInt(e.target.value, 10))}
            data={programmes.array.sort(
              (a, b) => a.niveauetude_id - b.niveauetude_id
            )}
            icon={<AssignmentOutlined />}
          />
          <Input
            placeholder="Prénom"
            value={firstname}
            ref={refs.firstname}
            rules={[rules.textMinLength(1), rules.textMaxLength(50)]}
            onChange={(e) => setFirstname(e.target.value)}
            icon={<PersonOutline />}
          />
          <Input
            placeholder="Nom"
            value={lastname}
            ref={refs.lastname}
            rules={[rules.textMinLength(1), rules.textMaxLength(50)]}
            onChange={(e) => setLastname(e.target.value)}
            icon={<PersonOutline />}
          />
          <Tel
            value={phone}
            ref={refs.telephone}
            rules={[rules.isValidPhoneNumber()]}
            onChange={(value, info) => {
              const valueReplaced =
                value.startsWith("+06") || value.startsWith("+07")
                  ? `+33${value.slice(2)}`
                  : value;
              setPhone(valueReplaced);
              formattedPhone.current = `+${info.countryCallingCode}${info.nationalNumber}`;
            }}
          />
          <Input
            type={"email"}
            placeholder="E-Mail"
            value={email}
            ref={refs.email}
            rules={[rules.email()]}
            onChange={(e) => setEmail(e.target.value)}
            icon={<EmailOutlined />}
          />
          <div
            style={{ display: "flex", alignItems: "center", fontSize: "0.9em" }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="consent"
                    value={checkedContact}
                    onChange={(e) => setCheckedContact(e.target.checked)}
                  />
                }
                label="J'accepte de recevoir des informations sur les formations Ynov par
                e-mail et par SMS"
              />
            </FormGroup>
          </div>
          <div
            style={{ textAlign: "center", fontSize: "0.9em", color: "#666" }}
          >
            Les informations recueillies dans ce formulaire font l'objet d'un
            traitement informatique ayant pour finalité la gestion de votre
            candidature (et, si vous l'avez accepté, l'envoi d'e-mails et/ou de
            SMS d'information) par la société YNOV. Dans le cadre de la gestion
            de votre candidature, vous serez contacté par téléphone et/ou par
            e-mail par les équipes en charge des admissions. Pour plus
            d'informations, nous vous invitons à lire notre{" "}
            <a href="https://privacy.ynov.com" target="_blank" rel="noreferrer">
              Politique de protection des données personnelles
            </a>
            . Vous pouvez à tout moment exercer vos droits (accès,
            rectification, effacement, limitation, portabilité, opposition, ...)
            en nous contactant via la plateforme{" "}
            <a href="https://privacy.ynov.com" target="_blank" rel="noreferrer">
              privacy.ynov.com
            </a>
            .
          </div>
          <div style={{ textAlign: "center" }}>
            <LoadingButton
              loading={form.postLoading}
              size="large"
              variant="contained"
              sx={{ color: "white" }}
              onClick={handleFormSubmit}
            >
              {getButtonTitleFromAction(type)}
            </LoadingButton>
          </div>
        </Stack>
      </div>
      {!layout.isIframe && <Footer />}
    </>
  );
};

export default Form;
