import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import axiosFactory, { AxiosError, AxiosInstance } from "axios";
import {
  DiplomeParcours,
  FormPostResponse,
  IncompleteFormObject,
  SignatureObject,
  SnackbarObject,
  YnovCampus,
  YnovCandidature,
  YnovCandidatureUpdate,
  YnovFinalizeStatus,
  YnovFormation,
  YnovFrais,
  YnovInseeCode,
  YnovPays,
  YnovProgramme,
  YnovSimpleTiers,
  YnovVille,
  YnovYear,
} from "../types/DataTypes";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, AlertColor, Slide, Snackbar } from "@mui/material";
import { CONNECT_ID, CONNECT_NAME } from "../constants/db-maps";

type DataContextType = {
  axios: AxiosInstance;
  signature?: FormPostResponse;
  trySignature: () => boolean;
  getSignatureString: (data: SignatureObject) => string;
  signAxios: (signature: string) => void;
  campus: {
    get: () => Promise<void>;
    array: YnovCampus[];
  };
  villes: {
    get: () => Promise<void>;
    getForTraining: (training_id: number) => Promise<YnovVille[]>;
    array: YnovVille[];
    sorted: YnovVille[];
  };
  formations: {
    get: (data: { campus_id?: number; ville_id?: number }) => Promise<void>;
    array: YnovFormation[];
  };
  programmes: {
    get: (
      data: { campus_id?: number; ville_id?: number },
      formation_id: number
    ) => Promise<void>;
    array: YnovProgramme[];
  };
  form: {
    post: (form: IncompleteFormObject) => Promise<void>;
    postLoading: boolean;
  };
  years: {
    get: () => Promise<void>;
    array: YnovYear[];
  };
  apply: {
    getInfos: () => Promise<boolean>;
    uploadDocument: (
      file: File,
      type: string,
      tiers_id: number | string,
      candidature_id: number | string
    ) => Promise<void>;
    updateCandidature: (
      tiers_id: number | string,
      candidature_id: number | string,
      data: YnovCandidatureUpdate
    ) => Promise<void>;
    candidature?: YnovCandidature;
    tiers?: YnovSimpleTiers;
    frais?: YnovFrais;
    candidatureType?: "apply" | "brochure";
    form?: IncompleteFormObject;
    diplomes: DiplomeParcours[];
  };
  candidatureList: {
    getInfos: () => Promise<boolean>;
    candidatures: YnovCandidature[];
    tiers?: YnovSimpleTiers;
  };
  finalize: {
    getFinalizeStatus: () => Promise<void>;
    finalizeStatus?: YnovFinalizeStatus;
  };
  layout: {
    isIframe: boolean;
    showSnackbar: (
      color: AlertColor,
      message: string,
      timeout?: number
    ) => void;
  };
  inseeCode: {
    get: (input?: string) => Promise<void>;
    inseeCode: YnovInseeCode[];
  };
  pays: {
    get: (input?: string) => Promise<void>;
    pays: YnovPays[];
  };
};

const DataContext = createContext<DataContextType | null>(null);
export const useData = () => useContext(DataContext);

const DataProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  // STATE
  const [searchParams] = useSearchParams();
  const signatureObject = useRef<FormPostResponse>();
  const [campus, setCampus] = useState<YnovCampus[]>([]);
  const [villes, setVilles] = useState<YnovVille[]>([]);
  const sortedVilles = useMemo(() => {
    const connect = villes.find((ville) => ville.id === CONNECT_ID);
    const sorted = villes
      .filter((ville) => ville.id !== CONNECT_ID)
      .sort((a, b) => a.nom.localeCompare(b.nom, "fr-FR"));
    if (connect) {
      connect.nom = CONNECT_NAME;
      sorted.push(connect);
    }
    return sorted;
  }, [villes]);
  const [formations, setFormations] = useState<YnovFormation[]>([]);
  const [programmes, setProgrammes] = useState<YnovProgramme[]>([]);
  const [tiers, setTiers] = useState<YnovSimpleTiers>();
  const [candidature, setCandidature] = useState<YnovCandidature>();
  const [candidatures, setCandidatures] = useState<YnovCandidature[]>([]);
  const [diplomes, setDiplomes] = useState<DiplomeParcours[]>([]);
  const [years, setYears] = useState<YnovYear[]>([]);
  const [form, setForm] = useState<IncompleteFormObject>();
  const [frais, setFrais] = useState<YnovFrais>();
  const [inseeCodeList, setInseeCodeList] = useState<YnovInseeCode[]>([]);
  const [paysList, setPaysList] = useState<YnovPays[]>([]);
  const [finalizeStatus, setFinalizeStatus] = useState<YnovFinalizeStatus>();
  const isIframe = useMemo(() => {
    return window.self !== window.top;
  }, []);
  const [snackbar, setSnackbar] = useState<SnackbarObject>({
    show: false,
    message: "",
    color: "info" as AlertColor,
  });
  const [postLoading, setPostLoading] = useState(false);
  const [candidatureType, setCandidatureType] = useState<
    "apply" | "brochure"
  >();
  // STATE

  // AXIOS - Network features and signature
  const axios = useMemo(
    () =>
      axiosFactory.create({
        baseURL: process.env.REACT_APP_API_HOST,
        params: {
          ...(searchParams.get("brand")
            ? {
                brand: searchParams.get("brand"),
              }
            : {}),
          ...(searchParams.get("intensif")
            ? { intensif: searchParams.get("intensif") }
            : {}),
        },
      }),
    [searchParams]
  );
  const getSignatureString = useCallback((data: SignatureObject) => {
    return `${data.tiers_id}/${data.tiers_id}/${data.hmac}${
      data.candidature_id ? `/${data.candidature_id}` : ""
    }`;
  }, []);
  const signAxios = useCallback(
    (signature: string) => {
      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: `Bearer ${signature}`,
      };
    },
    [axios.defaults.headers]
  );

  const getSignatureInfos = useCallback(() => {
    const hmac = searchParams.get("hmac");
    const token = searchParams.get("token");
    const candidature_id = searchParams.get("candidature_id");
    const salt = searchParams.get("salt");
    const tiers_id = searchParams.get("tiers_id");
    if (hmac && salt && tiers_id) {
      const token = `${tiers_id}/${salt}/${hmac}`;
      localStorage.setItem(
        "ystudentsweb-signature-object",
        JSON.stringify({ token, tiers_id })
      );
      signatureObject.current = { token, tiers_id: parseInt(tiers_id, 10) };
      signAxios(token);
    } else if (token && tiers_id && candidature_id) {
      localStorage.setItem(
        "ystudentsweb-signature-object",
        JSON.stringify({ token, tiers_id, candidature_id })
      );
      signatureObject.current = {
        token,
        tiers_id: parseInt(tiers_id, 10),
        candidature_id: parseInt(candidature_id, 10),
      };
      signAxios(token);
    } else {
      // trying to get the signature from the localStorage
      const rawKey = localStorage.getItem("ystudentsweb-signature-object");
      if (!rawKey) throw new Error("signature cryptographique manquante");
      const key = JSON.parse(rawKey) as FormPostResponse;
      signatureObject.current = key;
      signAxios(key.token);
    }
  }, [searchParams, signAxios]);
  const trySignature = useCallback(() => {
    try {
      getSignatureInfos();
      return true;
    } catch (error) {
      return false;
    }
  }, [getSignatureInfos]);
  // AXIOS - Network features and signature

  // TRACKING
  const ip = useRef("0.0.0.0");
  // TRACKING

  // GETTERS
  const getCampus = useCallback(async () => {
    try {
      const response = await axios.request<YnovCampus[]>({
        url: "ynovregister/structures",
        method: "GET",
        params: {
          est_campus: true,
          etat_id: 1,
          public: true,
          fields: "id,nom,ville_id,ville",
        },
      });
      setCampus(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        showSnackbar(
          "error",
          `${error.response?.status || "ERR"} - ${error.response?.data.message}`
        );
      }
      console.error(error);
    }
  }, [axios]);
  const getVilles = useCallback(async () => {
    try {
      const response = await axios.request<YnovVille[]>({
        url: "ynovregister/villes",
        method: "GET",
        params: {
          public: true,
          fields: "id,nom",
        },
      });
      setVilles(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        showSnackbar(
          "error",
          `${error.response?.status || "ERR"} - ${error.response?.data.message}`
        );
      }
      console.error(error);
    }
  }, [axios]);
  const getVillesForTraining = useCallback(
    async (training: number) => {
      try {
        const response = await axios.request<YnovVille[]>({
          url: `ynovregister/villes?training=${training}`,
          method: "GET",
        });
        return response.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          showSnackbar(
            "error",
            `${error.response?.status || "ERR"} - ${
              error.response?.data.message
            }`
          );
        }
        console.error(error);
        return [];
      }
    },
    [axios]
  );
  const getFormations = useCallback(
    async ({
      campus_id,
      ville_id,
    }: {
      campus_id?: number;
      ville_id?: number;
    }) => {
      try {
        if (!campus_id && !ville_id) throw new Error("bad data");
        const response = await axios.request<YnovFormation[]>({
          url: campus_id
            ? `ynovregister/structures/${campus_id}/formations`
            : `ynovregister/villes/${ville_id}/formations`,
          method: "GET",
        });
        setFormations(response.data);
      } catch (error) {
        if (error instanceof AxiosError) {
          showSnackbar(
            "error",
            `${error.response?.status || "ERR"} - ${
              error.response?.data.message
            }`
          );
        }
        console.error(error);
      }
    },
    [axios]
  );
  const getProgrammes = useCallback(
    async (
      {
        campus_id,
        ville_id,
      }: {
        campus_id?: number;
        ville_id?: number;
      },
      formation_id: number
    ) => {
      try {
        const response = await axios.request<YnovProgramme[]>({
          url: campus_id
            ? `ynovregister/structures/${campus_id}/formations/${formation_id}/programmes`
            : `ynovregister/villes/${ville_id}/formations/${formation_id}/programmes`,
          method: "GET",
        });
        setProgrammes(response.data);
      } catch (error) {
        if (error instanceof AxiosError) {
          showSnackbar(
            "error",
            `${error.response?.status || "ERR"} - ${
              error.response?.data.message
            }`
          );
        }
        console.error(error);
      }
    },
    [axios]
  );
  const getDiplomes = useCallback(async () => {
    setDiplomes(
      (
        await axios.request<DiplomeParcours[]>({
          url: "/ynovregister/diplomes",
        })
      ).data
    );
  }, [axios]);
  const getTiers = useCallback(
    async (tiers_id: number | string, candidature_id?: number | string) => {
      const tiersResponse = await axios.request({
        url: `ynovregister/tiers/${tiers_id}${
          candidature_id ? `/${candidature_id}` : ""
        }`,
      });
      setTiers(tiersResponse.data);
    },
    [axios]
  );
  const getCandidature = useCallback(
    async (tiers_id: number | string, candidature_id: number | string) => {
      const candidatureResponse = await axios.request({
        url: `ynovregister/tiers/${tiers_id}/candidatures/${candidature_id}`,
      });
      setCandidature(candidatureResponse.data);
    },
    [axios]
  );
  const getCandidatures = useCallback(
    async (tiers_id: number | string) => {
      const candidatureResponse = await axios.request({
        url: `ynovregister/tiers/${tiers_id}/candidatures`,
      });
      setCandidatures(candidatureResponse.data);
    },
    [axios]
  );
  const getFrais = useCallback(
    async (tiers_id: number | string, candidature_id: number | string) => {
      const fraisResponse = await axios.request({
        url: `ynovregister/tiers/${tiers_id}/candidatures/${candidature_id}/frais`,
      });
      setFrais(fraisResponse.data);
    },
    [axios]
  );
  const validateCandidature = useCallback(
    async (tiers_id: number | string, candidature_id: number | string) => {
      const validateResponse = await axios.request({
        url: `ynovregister/tiers/${tiers_id}/candidatures/${candidature_id}/validate`,
        method: "POST",
      });
      setFinalizeStatus(validateResponse.data);
    },
    [axios]
  );
  const getInseeCodes = useCallback(
    async (input?: string) => {
      if (input) {
        const inseeCodesResponse = await axios.request<YnovInseeCode[]>({
          url: `ynovregister/inseeCodes?city_name=%${input}%&limit=1000&sort=city_name`,
          method: "GET",
        });
        const sortedArray = inseeCodesResponse.data;
        sortedArray.sort((a, b) => {
          if (
            b.city_name.toLowerCase().startsWith(input.toLowerCase()) &&
            !a.city_name.toLowerCase().startsWith(input.toLowerCase())
          ) {
            return 1;
          } else if (
            a.city_name.toLowerCase().startsWith(input.toLowerCase()) &&
            !b.city_name.toLowerCase().startsWith(input.toLowerCase())
          ) {
            return -1;
          } else {
            return a.city_name.localeCompare(b.city_name);
          }
        });
        setInseeCodeList([...sortedArray.slice(0, 40)]);
      } else {
        setInseeCodeList([]);
      }
    },
    [axios]
  );

  const getPays = useCallback(
    async (input?: string) => {
      if (input) {
        const paysResponse = await axios.request<YnovPays[]>({
          url: `ynovregister/pays?nom=%${input}%&limit=200`,
          method: "GET",
        });
        setPaysList(paysResponse.data);
      } else {
        setPaysList([]);
      }
    },
    [axios]
  );
  // GETTERS

  // useEffects
  useEffect(() => {
    const getIp = async () => {
      ip.current = (
        await (await fetch("https://ipv4.icanhazip.com")).text()
      ).replace("\n", "");
    };

    getIp();
    if (searchParams.get("type")) {
      setCandidatureType(searchParams.get("type") as "apply" | "brochure");
    }
  }, [searchParams]);
  // useEffects

  // FORM
  const wipeLocalStorageForms = useCallback(() => {
    const buffer: string[] = [];
    for (const key in localStorage) {
      if (key.startsWith("ysw-form-")) {
        buffer.push(key);
      }
    }
    for (const key of buffer) {
      localStorage.removeItem(key);
    }
  }, []);
  const formPost = useCallback(
    async (form: IncompleteFormObject): Promise<void> => {
      try {
        setPostLoading(true);
        const response = await axios.request<FormPostResponse>({
          url: "ynovregister/form",
          method: "POST",
          data: { ...form, ip: ip.current },
        });
        // We sign axios
        signAxios(response.data.token);
        // We store the signature into the local storage
        localStorage.setItem(
          "ystudentsweb-signature-object",
          JSON.stringify(response.data)
        );
        // Storing the form info in localStorage
        wipeLocalStorageForms();
        localStorage.setItem(
          `ysw-form-${response.data.candidature_id}`,
          JSON.stringify(form)
        );

        // Post tracking info
        window.parent.postMessage(
          JSON.stringify({
            type: form.type === "brochure" ? "download" : "register",
            data: {
              formation_id: form.formation_id,
              formation_nom: formations.find(
                (formation) => formation.id === form.formation_id
              )?.nom,
              ville_id: form.ville_id,
              programme_id: form.programme_id,
              programme_nom: programmes.find(
                (programme) => programme.id === form.programme_id
              ),
            },
          }),
          "*"
        );
        // Post tracking info
        setTimeout(() => setPostLoading(true));
        setTimeout(() => {
          window.parent.location = `${process.env.REACT_APP_FRONT_HOST}/candidature?tiers_id=${response.data.tiers_id}&candidature_id=${response.data.candidature_id}&type=${form.type}&token=${response.data.token}&from=form`;
        }, 1000);
      } catch (error) {
        if (error instanceof AxiosError) {
          // Legacy
          if (
            error.response?.status === 500 &&
            Array.isArray(error.response?.data.data?.issues)
          ) {
            return showSnackbar(
              "error",
              `Votre formulaire comporte des erreurs : ${(
                error.response.data.data.issues as { message: string }[]
              )
                .map((err) => err.message)
                .join(", ")}`
            );
          }
          if (error.response?.status === 422) {
            if (Array.isArray(!error.response?.data.error?.details)) {
              showSnackbar(
                "error",
                `${
                  error.response?.status || "ERREUR"
                } - Les données fournies n'ont pas permis de vous inscrire, veuillez les vérifier`
              );
            } else {
              showSnackbar(
                "error",
                `Votre formulaire comporte des erreurs : ${(
                  error.response.data.error.details as string[]
                ).join(", ")}`
              );
            }
          } else if (Array.isArray(error.response?.data.message)) {
            showSnackbar(
              "error",
              `Votre formulaire comporte des erreurs : ${(
                error.response?.data.message as string[]
              ).join(", ")}`
            );
          }
        }
      } finally {
        setPostLoading(false);
      }
    },
    [axios, signAxios, wipeLocalStorageForms, formations, programmes]
  );
  // FORM

  // APPLICATION PAGE
  const getInfos = useCallback(async (): Promise<boolean> => {
    try {
      const tiers_id = searchParams.get("tiers_id");
      const candidature_id = searchParams.get("candidature_id");
      if (!tiers_id || !candidature_id) {
        throw new Error("Informations de candidature manquantes");
      }
      const retreivedForm = localStorage.getItem(`ysw-form-${candidature_id}`);
      if (retreivedForm) setForm(JSON.parse(retreivedForm));
      getSignatureInfos();
      // We're here, we can request the first piece of information : a simplified tiers
      await getTiers(tiers_id, candidature_id);
      await getCandidature(tiers_id, candidature_id);
      try {
        await getFrais(tiers_id, candidature_id);
      } catch (error) {
        // do nothing if 404, it just means that no fees apply
      }
      await getDiplomes();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
    // first we try to get the signature, we sign axios if success, else, we throw
  }, [
    searchParams,
    getSignatureInfos,
    getFrais,
    getCandidature,
    getTiers,
    getDiplomes,
  ]);

  const uploadDocument = useCallback(
    async (
      file: File,
      type: string,
      tiers_id: number | string,
      candidature_id: number | string
    ) => {
      const multipart = new FormData();
      multipart.append("file", file);
      multipart.append("type", type);

      await axios.request({
        url: `ynovregister/tiers/${tiers_id}/documents/${candidature_id}`,
        method: "POST",
        data: multipart,
      });
    },
    [axios]
  );
  const updateCandidature = useCallback(
    async (
      tiers_id: number | string,
      candidature_id: number | string,
      data: YnovCandidatureUpdate
    ) => {
      await axios.request({
        url: `ynovregister/tiers/${tiers_id}/candidatures/${candidature_id}`,
        method: "PATCH",
        data,
      });
    },
    [axios]
  );
  // APPLICATION PAGE
  // FINALIZE PAGE
  const getFinalizeStatus = useCallback(async () => {
    try {
      const tiers_id = searchParams.get("tiers_id");
      const candidature_id = searchParams.get("candidature_id");
      if (!tiers_id || !candidature_id) {
        throw new Error("Informations de candidature manquantes");
      }
      await validateCandidature(tiers_id, candidature_id);
    } catch (error) {
      console.error(error);
    }
  }, [searchParams, validateCandidature]);
  const getYears = useCallback(async () => {
    try {
      setYears((await axios.get("year")).data);
    } catch (error) {
      console.error(error);
    }
  }, [axios]);
  // FINALIZE PAGE

  // LIST PAGE
  const getListInfos = useCallback(async (): Promise<boolean> => {
    try {
      const tiers_id = searchParams.get("tiers_id");
      if (!tiers_id) {
        throw new Error("Informations de tiers manquant");
      }
      getSignatureInfos();
      // We're here, we can request the first piece of information : a simplified tiers
      await getTiers(tiers_id);
      await getCandidatures(tiers_id);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
    // first we try to get the signature, we sign axios if success, else, we throw
  }, [searchParams, getSignatureInfos, getTiers, getCandidatures]);
  // LIST PAGE

  // LAYOUT
  const showSnackbar = (color: AlertColor, message: string, timeout = 5000) => {
    setSnackbar({
      show: true,
      message,
      color,
    });
    setTimeout(() => {
      setSnackbar({
        show: false,
        message,
        color,
      });
    }, timeout);
  };
  // LAYOUT

  return (
    <DataContext.Provider
      value={{
        axios,
        getSignatureString,
        trySignature,
        signature: signatureObject.current,
        signAxios,
        campus: {
          array: campus,
          get: getCampus,
        },
        villes: {
          array: villes,
          sorted: sortedVilles,
          get: getVilles,
          getForTraining: getVillesForTraining,
        },
        formations: {
          array: formations,
          get: getFormations,
        },
        programmes: {
          array: programmes,
          get: getProgrammes,
        },
        form: {
          post: formPost,
          postLoading,
        },
        apply: {
          getInfos,
          uploadDocument,
          updateCandidature,
          candidature,
          tiers,
          frais,
          candidatureType,
          form,
          diplomes,
        },
        candidatureList: {
          getInfos: getListInfos,
          candidatures,
          tiers,
        },
        finalize: {
          getFinalizeStatus,
          finalizeStatus,
        },
        years: {
          array: years,
          get: getYears,
        },
        layout: {
          isIframe,
          showSnackbar,
        },
        inseeCode: {
          get: getInseeCodes,
          inseeCode: inseeCodeList,
        },
        pays: {
          get: getPays,
          pays: paysList,
        },
      }}
    >
      <Snackbar
        open={snackbar.show}
        sx={{ backgroundColor: "primary" }}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.color}>{snackbar.message}</Alert>
      </Snackbar>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
