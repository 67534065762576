export type DataGouvSchool = {
    datasetid: string,
    recordid: string
    fields: {
        code_nature: number
        nom_etablissement: string
        adresse_1: string
        adresse_2?: string
        code_postal: string
        nom_commune: string
    }
}

type DataGouvSchoolsResponse = {
    nhits: number,
    parameters: Record<string, any>,
    records: DataGouvSchool[]
}


const getSchools = async (query: string): Promise<DataGouvSchoolsResponse> => {
    const response = await fetch(`https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q={${query}}&exclude.type_etablissement=Ecole&exclude.type_etablissement=Collège`)

    return response.json() as Promise<DataGouvSchoolsResponse>
}

export default getSchools