import { parsePhoneNumber } from "libphonenumber-js";
import { DocumentType } from "../types/DataTypes";
export const getButtonTitleFromAction = (action: string) => {
  switch (action) {
    case "apply":
      return "Je Candidate";
    case "jpo":
      return "Je participe à la journée portes ouvertes";
    case "brochure":
      return "J'obtiens ma brochure";
  }
};

export const getFormattedPhoneNumber = (phone: string): string => {
  try {
    if (phone) {
      return parsePhoneNumber(
        phone.startsWith("+") ? phone : `+${phone}`
      ).formatInternational();
    }
  } catch (error) {
    return `+${phone.slice(0, 2)} ${phone.slice(2, 3)} ${phone.slice(3)}`;
  }
  return "";
};

export const getDocumentTypeName = (type: DocumentType) => {
  switch (type) {
    case DocumentType.PROOFOFID:
      return "Justificatif d'identité";
    case DocumentType.SCHOOL_REPORT:
      return "Bulletin de notes";
    case DocumentType.CV:
      return "Curriculum Vitae";
    case DocumentType.ID_PICTURE:
      return "Photo d'identité";
    case DocumentType.DIPLOMA:
      return "Diplôme";
    default:
      return "Document";
  }
};
