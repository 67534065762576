import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/Wrapper.scss";
import useStickyFooter from "../hooks/stickyFooter";

type WrapperProps = {
  children: React.ReactNode;
  title?: string;
};

const Wrapper: React.FC<WrapperProps> = ({ children, title }) => {
  const stickyFooter = useStickyFooter();

  return (
    <div>
      <Header />
      {title && (
        <div className="p-14 bg-slate-100">
          <div className="container mx-auto">
            <div className="ml-3 font-bold text-4xl title-underlined">
              {title}
            </div>
          </div>
        </div>
      )}
      <div className="container mx-auto">{children}</div>
      <div className={stickyFooter}>
        <Footer />
      </div>
    </div>
  );
};

export default Wrapper;
