
// Filter par "est_campus"
export const fakeCampus = [
    {
        "id": 25,
        "nom": "Connect Ynov Campus",
        "date_creation": "2022-09-30 12:00:43",
        "date_modification": "2022-09-30 12:00:43",
        "ville_id": 14,
        "ville": "BORDEAUX",
        "etat_id": 0,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": true,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/25",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 24,
        "nom": "UpSchool",
        "date_creation": "2021-09-28 12:52:17",
        "date_modification": "2021-09-28 12:52:17",
        "ville_id": null,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/24",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 23,
        "nom": "Rennes Ynov Campus",
        "date_creation": "2021-08-26 09:43:09",
        "date_modification": "2021-08-26 09:43:09",
        "ville_id": 13,
        "ville": "RENNES",
        "etat_id": 1,
        "capital_social": 10000,
        "rcs_ville": "Rennes",
        "directeur_id": 304250,
        "entreprise_id": 319328,
        "est_campus": true,
        "academie_numero": null,
        "academie_nom": "Rennes",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": "Rennes",
        "href": "https://api.ynov.eu/v1/structures/23",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 319328,
            "nom": "RENNES YNOV CAMPUS",
            "prenom": null,
            "adresse_1": "1 Rue du Docteur Francis Joly",
            "adresse_2": null,
            "adresse_3": null,
            "cp": "35000",
            "ville": "RENNES",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2021-09-16 13:59:15",
            "date_modification": "2021-09-16 13:59:15",
            "date_derconnexion": null,
            "civilite_id": 68,
            "ddn": null,
            "vdn": null,
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": null
        }
    },
    {
        "id": 22,
        "nom": "Lille Ynov Campus",
        "date_creation": "2021-08-26 09:43:06",
        "date_modification": "2021-08-26 09:43:06",
        "ville_id": 12,
        "ville": "LILLE",
        "etat_id": 1,
        "capital_social": 10000,
        "rcs_ville": "Lille",
        "directeur_id": 308574,
        "entreprise_id": 319324,
        "est_campus": true,
        "academie_numero": null,
        "academie_nom": "Lille",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": "Lille",
        "href": "https://api.ynov.eu/v1/structures/22",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 319324,
            "nom": "LILLE YNOV CAMPUS",
            "prenom": null,
            "adresse_1": "19 RUE D AMIENS",
            "adresse_2": null,
            "adresse_3": null,
            "cp": "59800",
            "ville": "LILLE",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2021-09-16 13:54:46",
            "date_modification": "2021-09-16 13:54:46",
            "date_derconnexion": null,
            "civilite_id": 68,
            "ddn": null,
            "vdn": null,
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": null
        }
    },
    {
        "id": 21,
        "nom": "Montpellier Ynov Campus",
        "date_creation": "2021-08-26 09:43:01",
        "date_modification": "2021-08-26 09:43:01",
        "ville_id": 11,
        "ville": "MONTPELLIER",
        "etat_id": 1,
        "capital_social": 10000,
        "rcs_ville": "Montpellier",
        "directeur_id": 209899,
        "entreprise_id": 319326,
        "est_campus": true,
        "academie_numero": null,
        "academie_nom": "Montpellier",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": "Montpellier",
        "href": "https://api.ynov.eu/v1/structures/21",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 319326,
            "nom": "MONTPELLIER YNOV CAMPUS",
            "prenom": "",
            "adresse_1": "105 RUE STEVE JOBS",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "34000",
            "ville": "MONTPELLIER",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2021-09-16 13:56:47",
            "date_modification": "2022-09-08 14:21:23",
            "date_derconnexion": null,
            "civilite_id": 68,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 20,
        "nom": "SIPCA",
        "date_creation": "2021-02-19 10:41:59",
        "date_modification": "2021-02-19 10:41:59",
        "ville_id": 5,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/20",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 19,
        "nom": "CAPYNOV",
        "date_creation": "2021-02-19 10:39:15",
        "date_modification": "2021-02-19 10:39:15",
        "ville_id": 7,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/19",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 18,
        "nom": "APAXX DESIGNS",
        "date_creation": "2021-02-19 10:38:26",
        "date_modification": "2021-02-19 10:38:26",
        "ville_id": 5,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": 130476,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/18",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 130476,
            "nom": "APAXX DESIGNS",
            "prenom": "",
            "adresse_1": "159 RUE AMELOT",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "75011",
            "ville": "PARIS",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2018-01-18 08:30:02",
            "date_modification": "2021-02-19 10:19:59",
            "date_derconnexion": null,
            "civilite_id": 48,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 17,
        "nom": "IDG",
        "date_creation": "2020-10-01 12:57:00",
        "date_modification": "2020-10-01 12:57:00",
        "ville_id": null,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/17",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 16,
        "nom": "Sophia Ynov Campus",
        "date_creation": "2020-09-24 12:42:18",
        "date_modification": "2020-09-24 15:08:47",
        "ville_id": 8,
        "ville": "SOPHIA",
        "etat_id": 1,
        "capital_social": 10000,
        "rcs_ville": "Grasse",
        "directeur_id": 262101,
        "entreprise_id": 257295,
        "est_campus": true,
        "academie_numero": "006 2311 E",
        "academie_nom": "Nice",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": "93060903906",
        "prefecture_ville": "Grasse",
        "href": "https://api.ynov.eu/v1/structures/16",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 257295,
            "nom": "SOPHIA YNOV CAMPUS",
            "prenom": "",
            "adresse_1": " PLACE SOPHIE LAFFITTE",
            "adresse_2": null,
            "adresse_3": null,
            "cp": "06560",
            "ville": "VALBONNE",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2020-09-24 14:21:34",
            "date_modification": "2022-06-06 06:20:35",
            "date_derconnexion": null,
            "civilite_id": 68,
            "ddn": null,
            "vdn": null,
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 15,
        "nom": "ADAMA",
        "date_creation": "2019-04-19 10:08:48",
        "date_modification": "2019-04-19 10:08:48",
        "ville_id": 7,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/15",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 14,
        "nom": "Lyon EICAR Campus",
        "date_creation": "2018-02-14 14:56:12",
        "date_modification": "2018-02-14 14:56:12",
        "ville_id": 3,
        "ville": "VILLEURBANNE",
        "etat_id": 1,
        "capital_social": 182500,
        "rcs_ville": "Lyon",
        "directeur_id": 236267,
        "entreprise_id": 107353,
        "est_campus": true,
        "academie_numero": "069 4119 C",
        "academie_nom": "Lyon",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": "826910163",
        "prefecture_ville": "Lyon",
        "href": "https://api.ynov.eu/v1/structures/14",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 107353,
            "nom": "LYON EICAR CAMPUS",
            "prenom": "",
            "adresse_1": "67 AVENUE GALLINE",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "69100",
            "ville": "VILLEURBANNE",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2017-06-16 07:54:45",
            "date_modification": "2021-08-27 10:56:52",
            "date_derconnexion": null,
            "civilite_id": 68,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 13,
        "nom": "Paris EICAR Campus",
        "date_creation": "2015-09-18 08:17:45",
        "date_modification": "2020-10-07 09:09:09",
        "ville_id": 5,
        "ville": "IVRY-SUR-SEINE",
        "etat_id": 1,
        "capital_social": 520632,
        "rcs_ville": "Créteil",
        "directeur_id": 38574,
        "entreprise_id": 21886,
        "est_campus": true,
        "academie_numero": "094 2515 Y",
        "academie_nom": "Créteil",
        "cpf_id_client": "03VMP584",
        "cpf_id_contrat": "MCFCER000330",
        "prefecture_numero_declaration": "11930536493",
        "prefecture_ville": "Créteil",
        "href": "https://api.ynov.eu/v1/structures/13",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 21886,
            "nom": "PARIS EICAR CAMPUS",
            "prenom": "",
            "adresse_1": "1 ALLEE LEPREST",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "94200",
            "ville": "IVRY-SUR-SEINE",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2015-12-01 09:31:57",
            "date_modification": "2021-09-16 14:04:40",
            "date_derconnexion": null,
            "civilite_id": 68,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": "9f35ab4edc6c896cca7e9586a17df6ae1e662fd5544342d838df4bca521dcbfc",
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 12,
        "nom": "Paris Ynov Campus",
        "date_creation": "2015-09-18 08:17:36",
        "date_modification": "2017-08-31 13:55:08",
        "ville_id": 5,
        "ville": "PARIS",
        "etat_id": 1,
        "capital_social": 10000,
        "rcs_ville": "Paris",
        "directeur_id": 307597,
        "entreprise_id": 1179,
        "est_campus": true,
        "academie_numero": "092 2816 L",
        "academie_nom": "Versailles",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": "11754744275",
        "prefecture_ville": "Nanterre",
        "href": "https://api.ynov.eu/v1/structures/12",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 1179,
            "nom": "PARIS YNOV CAMPUS",
            "prenom": "",
            "adresse_1": "12 RUE ANATOLE FRANCE",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "92000",
            "ville": "NANTERRE",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2015-09-01 20:24:52",
            "date_modification": "2022-06-20 13:06:16",
            "date_derconnexion": null,
            "civilite_id": 70,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": null,
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 11,
        "nom": "ISEE",
        "date_creation": "2015-09-18 08:17:30",
        "date_modification": "2016-04-11 16:43:52",
        "ville_id": 5,
        "ville": "PARIS",
        "etat_id": 0,
        "capital_social": 10000,
        "rcs_ville": "Paris",
        "directeur_id": 9760,
        "entreprise_id": 1181,
        "est_campus": true,
        "academie_numero": "075 5692 E",
        "academie_nom": "Paris",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": "11755385075",
        "prefecture_ville": "Paris",
        "href": "https://api.ynov.eu/v1/structures/11",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 1181,
            "nom": "ISEE",
            "prenom": "",
            "adresse_1": "179 RUE DE CHARONNE",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "75011",
            "ville": "PARIS",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2015-09-01 20:24:52",
            "date_modification": "2017-09-25 14:22:04",
            "date_derconnexion": null,
            "civilite_id": 67,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": null,
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 10,
        "nom": "COM-YN",
        "date_creation": "2015-09-18 08:17:26",
        "date_modification": "2015-09-18 08:17:26",
        "ville_id": 5,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/10",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 9,
        "nom": "Lyon Ynov Campus",
        "date_creation": "2015-09-18 08:17:22",
        "date_modification": "2016-04-11 16:43:52",
        "ville_id": 3,
        "ville": "LYON",
        "etat_id": 1,
        "capital_social": 10000,
        "rcs_ville": "Lyon",
        "directeur_id": 8490,
        "entreprise_id": 1178,
        "est_campus": true,
        "academie_numero": "069 4251 W",
        "academie_nom": "Lyon",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": "82691351669",
        "prefecture_ville": "Lyon",
        "href": "https://api.ynov.eu/v1/structures/9",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 1178,
            "nom": "LYON YNOV CAMPUS",
            "prenom": "",
            "adresse_1": "27 RUE RAOUL SERVANT",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "69007",
            "ville": "LYON",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2015-09-01 20:24:52",
            "date_modification": "2018-11-06 09:35:31",
            "date_derconnexion": null,
            "civilite_id": 67,
            "ddn": null,
            "vdn": "",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": null,
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 8,
        "nom": "Nantes Ynov Campus",
        "date_creation": "2015-09-18 08:17:18",
        "date_modification": "2016-04-11 16:43:52",
        "ville_id": 4,
        "ville": "NANTES",
        "etat_id": 1,
        "capital_social": 31600,
        "rcs_ville": "Nantes",
        "directeur_id": 303199,
        "entreprise_id": 10837,
        "est_campus": true,
        "academie_numero": "044 2814 V",
        "academie_nom": "Nantes",
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": "52440756544",
        "prefecture_ville": "Nantes",
        "href": "https://api.ynov.eu/v1/structures/8",
        "schooling_certificate_enabled": false,
        "entreprise": {
            "id": 10837,
            "nom": "NANTES YNOV CAMPUS",
            "prenom": "",
            "adresse_1": "20 BOULEVARD DU GENERAL DE GAULLE",
            "adresse_2": "",
            "adresse_3": "",
            "cp": "44200",
            "ville": "NANTES",
            "pays": "FR",
            "physique": false,
            "verrou": null,
            "date_creation": "2015-10-01 18:11:52",
            "date_modification": "2017-09-25 14:07:06",
            "date_derconnexion": null,
            "civilite_id": 70,
            "ddn": null,
            "vdn": "NANTES",
            "nationalite": null,
            "date_modifmdp": null,
            "pays_naissance": null,
            "departement_naissance": null,
            "apiuser_creation": null,
            "avatar": null,
            "prenom_usage": null,
            "naf_code": null,
            "idcc_code": null,
            "code_insee_ville_naissance": null,
            "prenom_civil": ""
        }
    },
    {
        "id": 7,
        "nom": "OBI",
        "date_creation": "2015-09-18 08:17:12",
        "date_modification": "2015-09-18 08:17:12",
        "ville_id": 7,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/7",
        "schooling_certificate_enabled": false,
        "entreprise": null
    },
    {
        "id": 6,
        "nom": "DOMAINES ET RESIDENCES PROMOTION",
        "date_creation": "2015-09-18 08:17:03",
        "date_modification": "2015-09-18 08:17:03",
        "ville_id": 7,
        "ville": null,
        "etat_id": 1,
        "capital_social": null,
        "rcs_ville": null,
        "directeur_id": null,
        "entreprise_id": null,
        "est_campus": false,
        "academie_numero": null,
        "academie_nom": null,
        "cpf_id_client": null,
        "cpf_id_contrat": null,
        "prefecture_numero_declaration": null,
        "prefecture_ville": null,
        "href": "https://api.ynov.eu/v1/structures/6",
        "schooling_certificate_enabled": false,
        "entreprise": null
    }
]
export const fakeFormations = [
    {
        "id": 34,
        "nom": "Ynov Sound Design",
        "date_creation": "2021-09-23 14:18:37",
        "date_modification": "2021-09-23 14:18:37",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov Sound",
        "href": "https://api.ynov.eu/v1/formations/34"
    },
    {
        "id": 33,
        "nom": "Ynov Web Management",
        "date_creation": "2021-09-23 14:18:16",
        "date_modification": "2021-09-23 14:18:16",
        "etat_id": 0,
        "actif_prestation": true,
        "nom_court": "Ynov WebMgmt",
        "href": "https://api.ynov.eu/v1/formations/33"
    },
    {
        "id": 32,
        "nom": "Ynov Création & Digital Design",
        "date_creation": "2021-09-23 14:17:29",
        "date_modification": "2021-09-23 14:17:29",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov CréaDesign",
        "href": "https://api.ynov.eu/v1/formations/32"
    },
    {
        "id": 31,
        "nom": "Ynov Architecture d'Intérieur",
        "date_creation": "2021-09-23 14:17:01",
        "date_modification": "2021-09-23 14:17:01",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov Archi",
        "href": "https://api.ynov.eu/v1/formations/31"
    },
    {
        "id": 30,
        "nom": "Ynov 3D, Animation & Jeux Vidéo",
        "date_creation": "2021-09-23 14:16:32",
        "date_modification": "2021-09-23 14:16:32",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov 3DAJV",
        "href": "https://api.ynov.eu/v1/formations/30"
    },
    {
        "id": 29,
        "nom": "Ynov 2D & Illustration Digitale",
        "date_creation": "2021-09-23 14:03:27",
        "date_modification": "2021-09-23 14:03:27",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov 2DIllu",
        "href": "https://api.ynov.eu/v1/formations/29"
    },
    {
        "id": 28,
        "nom": "Ynov Formations Spécifiques",
        "date_creation": "2021-06-28 08:05:01",
        "date_modification": "2021-06-28 08:05:01",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov FormSpé",
        "href": "https://api.ynov.eu/v1/formations/28"
    },
    {
        "id": 27,
        "nom": "CampusID",
        "date_creation": "2021-04-19 11:05:05",
        "date_modification": "2021-04-19 11:05:05",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "CampusID",
        "href": "https://api.ynov.eu/v1/formations/27"
    },
    {
        "id": 26,
        "nom": "IDG Informatique",
        "date_creation": "2020-10-01 13:00:14",
        "date_modification": "2020-10-01 13:00:14",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "IDG Info",
        "href": "https://api.ynov.eu/v1/formations/26"
    },
    {
        "id": 25,
        "nom": "SIPCA",
        "date_creation": "2020-01-15 10:36:50",
        "date_modification": "2020-01-15 10:36:50",
        "etat_id": 0,
        "actif_prestation": true,
        "nom_court": "SIPCA",
        "href": "https://api.ynov.eu/v1/formations/25"
    },
    {
        "id": 24,
        "nom": "APAXX DESIGNS",
        "date_creation": "2020-01-15 10:36:36",
        "date_modification": "2020-01-15 10:36:36",
        "etat_id": 0,
        "actif_prestation": true,
        "nom_court": "APAXXDESIGNS",
        "href": "https://api.ynov.eu/v1/formations/24"
    },
    {
        "id": 23,
        "nom": "Ynov Robotique & Ingénierie Systèmes",
        "date_creation": "2019-09-17 08:48:51",
        "date_modification": "2019-09-17 08:48:51",
        "etat_id": 0,
        "actif_prestation": true,
        "nom_court": "Ynov Robot",
        "href": "https://api.ynov.eu/v1/formations/23"
    },
    {
        "id": 22,
        "nom": "Ynov Marketing & Communication",
        "date_creation": "2018-09-05 10:46:13",
        "date_modification": "2018-09-05 10:46:13",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov Market",
        "href": "https://api.ynov.eu/v1/formations/22"
    },
    {
        "id": 21,
        "nom": "Ynov Création & Design",
        "date_creation": "2018-09-05 10:45:59",
        "date_modification": "2018-09-05 10:45:59",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov Créa",
        "href": "https://api.ynov.eu/v1/formations/21"
    },
    {
        "id": 20,
        "nom": "Ynov POEC",
        "date_creation": "2018-03-16 10:37:23",
        "date_modification": "2018-03-16 10:37:23",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov POEC",
        "href": "https://api.ynov.eu/v1/formations/20"
    },
    {
        "id": 19,
        "nom": "Ynov MANAA",
        "date_creation": "2018-02-07 10:06:09",
        "date_modification": "2018-02-07 10:06:09",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov MANAA",
        "href": "https://api.ynov.eu/v1/formations/19"
    },
    {
        "id": 18,
        "nom": "Ynov Aéronautique & Systèmes embarqués",
        "date_creation": "2017-11-09 14:32:42",
        "date_modification": "2017-11-09 14:32:42",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov Aéro",
        "href": "https://api.ynov.eu/v1/formations/18"
    },
    {
        "id": 17,
        "nom": "Ynov Mastères",
        "date_creation": "2017-11-08 13:44:12",
        "date_modification": "2017-11-08 13:44:12",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov Mast",
        "href": "https://api.ynov.eu/v1/formations/17"
    },
    {
        "id": 16,
        "nom": "Ynov Audiovisuel",
        "date_creation": "2017-11-02 10:23:56",
        "date_modification": "2017-11-02 10:23:56",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov AV",
        "href": "https://api.ynov.eu/v1/formations/16"
    },
    {
        "id": 15,
        "nom": "Ynov Animation 3D Jeux Vidéo",
        "date_creation": "2017-11-02 10:23:40",
        "date_modification": "2017-11-02 10:23:40",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov A3DJV",
        "href": "https://api.ynov.eu/v1/formations/15"
    },
    {
        "id": 14,
        "nom": "Ynov Web, Com & Graphic Design",
        "date_creation": "2017-11-02 10:22:58",
        "date_modification": "2017-11-02 10:22:58",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov WCGD",
        "href": "https://api.ynov.eu/v1/formations/14"
    },
    {
        "id": 13,
        "nom": "Ynov Digital Business School",
        "date_creation": "2017-11-02 10:22:24",
        "date_modification": "2017-11-02 10:22:24",
        "etat_id": 0,
        "actif_prestation": false,
        "nom_court": "Ynov DBS",
        "href": "https://api.ynov.eu/v1/formations/13"
    },
    {
        "id": 12,
        "nom": "Ynov Informatique",
        "date_creation": "2017-11-02 10:22:06",
        "date_modification": "2017-11-02 10:22:06",
        "etat_id": 1,
        "actif_prestation": true,
        "nom_court": "Ynov Info",
        "href": "https://api.ynov.eu/v1/formations/12"
    },
]
export const fakeNiveau = [
    {
        "id": 9,
        "nom": "Bac+6",
        "date_creation": "2016-04-11 15:10:45",
        "date_modification": "2016-04-11 15:10:45",
        "nom_fr": "Bac+6",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/9"
    },
    {
        "id": 8,
        "nom": "Bac+5",
        "date_creation": "2015-10-20 09:59:45",
        "date_modification": "2015-10-20 09:59:45",
        "nom_fr": "Bac+5",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/8"
    },
    {
        "id": 7,
        "nom": "Bac+4",
        "date_creation": "2015-10-20 09:59:42",
        "date_modification": "2015-10-20 09:59:42",
        "nom_fr": "Bac+4",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/7"
    },
    {
        "id": 6,
        "nom": "Bac+3",
        "date_creation": "2015-10-20 09:59:37",
        "date_modification": "2015-10-20 09:59:37",
        "nom_fr": "Bac+3",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/6"
    },
    {
        "id": 5,
        "nom": "Bac+2",
        "date_creation": "2015-10-20 09:59:35",
        "date_modification": "2015-10-20 09:59:35",
        "nom_fr": "Bac+2",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/5"
    },
    {
        "id": 4,
        "nom": "Bac+1",
        "date_creation": "2015-10-20 09:59:26",
        "date_modification": "2015-10-20 09:59:26",
        "nom_fr": "Bac+1",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/4"
    },
    {
        "id": 3,
        "nom": "Terminale",
        "date_creation": "2015-10-20 09:59:16",
        "date_modification": "2015-10-20 09:59:16",
        "nom_fr": "Terminale",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/3"
    },
    {
        "id": 2,
        "nom": "Première",
        "date_creation": "2015-10-20 09:59:10",
        "date_modification": "2015-10-20 09:59:10",
        "nom_fr": "Première",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/2"
    },
    {
        "id": 1,
        "nom": "Seconde",
        "date_creation": "2015-10-20 09:58:58",
        "date_modification": "2015-10-20 09:58:58",
        "nom_fr": "Seconde",
        "nom_en": null,
        "href": "https://api.ynov.eu/v1/niveauetudes/1"
    }
]