import React, { useCallback } from "react";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import TrackingProvider from "./providers/TrackingProvider";
import Apply from "./views/Apply";
import CandidatureList from "./views/CandidatureList";
import Finalize from "./views/Finalize";
import Form from "./views/Form";

const App: React.FC<{}> = () => {
  const [searchParams] = useSearchParams();
  const getApplyTitle = useCallback(() => {
    switch (searchParams.get("type")) {
      case "apply":
        return "Votre Candidature";
      case "brochure":
        return "Téléchargez votre brochure";
      default:
        return "Votre Candidature";
    }
  }, [searchParams]);

  return (
    <Routes>
      <Route
        path="/form"
        element={
          <TrackingProvider>
            <Form />
          </TrackingProvider>
        }
      />
      <Route
        path="/candidature"
        element={
          <Wrapper title={getApplyTitle()}>
            <Apply />
          </Wrapper>
        }
      />
      <Route
        path="/finalize"
        element={
          <Wrapper title="Votre Candidature">
            <Finalize />
          </Wrapper>
        }
      />
      <Route
        path="/candidatures"
        element={
          <Wrapper title="Vos Candidatures">
            <CandidatureList />
          </Wrapper>
        }
      />
      <Route path="*" element={<Navigate to={"/form"} replace />} />
    </Routes>
  );
};

export default App;
