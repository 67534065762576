import { InputAdornment, MenuItem, TextField } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { ValidationRule } from "../helpers/rules-manager";

type SelectProps = {
  value: number;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  icon?: React.ReactNode;
  data: (Record<string, any> & { id: number; nom: string })[];
  disabled?: boolean;
  rules?: ValidationRule[];
  hidden?: boolean;
};
export type SelectHandle = {
  validate: () => boolean;
};

const Select: React.ForwardRefRenderFunction<SelectHandle, SelectProps> = (
  props,
  forwardedRef
) => {
  const validate = useCallback(() => {
    if (props.rules) {
      for (const rule of props.rules) {
        const result = rule(props.value);
        if (!(result === true)) {
          setHelperText(result);
          setRuleError(true);
          return false;
        }
      }
    }
    setHelperText("");
    setRuleError(false);
    return true;
  }, [props.rules, props.value]);

  useImperativeHandle(forwardedRef, () => ({
    validate,
  }));

  const [helperText, setHelperText] = useState("");
  const [ruleError, setRuleError] = useState(false);

  return (
    <TextField
      style={{ display: props.hidden ? "none" : "initial" }}
      fullWidth
      variant="standard"
      select
      disabled={props.disabled}
      value={props.value}
      error={ruleError}
      onBlur={validate}
      helperText={helperText}
      onChange={props.onChange}
      InputProps={{
        ...(props.icon
          ? {
              startAdornment: (
                <InputAdornment position="start">{props.icon}</InputAdornment>
              ),
            }
          : {}),
      }}
    >
      <MenuItem value={0}>
        <div
          style={{
            color: "lightgrey",
            textTransform: "uppercase",
            fontSize: "0.8em",
          }}
        >
          {props.placeholder}
        </div>
      </MenuItem>
      {props.data.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {item.nom}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default forwardRef(Select);
