import { PhoneOutlined, PlaceOutlined, School } from "@mui/icons-material";
import { Paper } from "@mui/material";
import React from "react";

import ynovLogo from "../assets/logo_ynov.png";

type HeaderProps = {};
type HeaderLink = { text: string; url: string; icon: React.ReactElement };

const links: HeaderLink[] = [
  {
    text: "Ecole",
    url: "https://www.ynov.com/qui-sommes-nous/",
    icon: <School />,
  },
  {
    text: "Contact",
    url: "https://www.ynov.com/contact/",
    icon: <PlaceOutlined />,
  },
  {
    text: "0800 600 633",
    url: "tel:+33800600633",
    icon: <PhoneOutlined />,
  },
];

const Header: React.FC<HeaderProps> = () => {
  return (
    <div>
      <Paper
        square
        sx={{
          backgroundColor: "primary.main",
        }}
        className="py-3"
      >
        <div className="container flex justify-end mx-auto">
          {links.map((link) => (
            <a
              key={link.text}
              href={link.url}
              target="_blank"
              className="flex cursor-pointer items-center mx-2 md:mx-5"
              rel="noreferrer"
            >
              <div className="mr-2">{link.icon}</div>
              <div>{link.text}</div>
            </a>
          ))}
        </div>
      </Paper>
      <Paper square sx={{ backgroundColor: "secondary.main", color: "white" }}>
        <div className="container mx-auto flex">
          <a
            href="http://www.ynov.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="h-20 px-5 py-2"
              src={ynovLogo}
              alt="Logo Ynov Campus"
            />
          </a>
        </div>
      </Paper>
    </div>
  );
};

export default Header;
