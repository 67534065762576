import { BaseTextFieldProps, InputAdornment, TextField } from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ValidationRule } from "../helpers/rules-manager";

type InputProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  rules?: ValidationRule[];
  type?: BaseTextFieldProps["type"];
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};
export type InputHandle = {
  validate: () => boolean;
};

const Input: React.ForwardRefRenderFunction<InputHandle, InputProps> = (
  props,
  forwardedRef
) => {
  const validate = useCallback(() => {
    if (props.rules) {
      for (const rule of props.rules) {
        const result = rule(textfield.current?.value);
        if (!(result === true)) {
          setHelperText(result);
          setRuleError(true);
          return false;
        }
      }
    }
    setHelperText("");
    setRuleError(false);
    return true;
  }, [props.rules]);

  useImperativeHandle(forwardedRef, () => ({
    validate,
  }));
  const [helperText, setHelperText] = useState("");
  const [ruleError, setRuleError] = useState(false);
  const textfield = useRef<HTMLInputElement>(null);

  const lazyValidate = useCallback(() => {
    if (ruleError) {
      // validate only if the error is set
      validate();
    }
  }, [ruleError, validate]);

  return (
    <TextField
      inputRef={textfield}
      type={props.type}
      placeholder={props.placeholder}
      fullWidth
      disabled={props.disabled}
      variant="standard"
      value={props.value}
      error={ruleError}
      onFocus={props.onFocus}
      onChange={(e) => {
        lazyValidate();
        props.onChange(e);
      }}
      onBlur={validate}
      helperText={helperText}
      InputProps={{
        ...(props.icon
          ? {
              startAdornment: (
                <InputAdornment position="start">{props.icon}</InputAdornment>
              ),
            }
          : {}),
      }}
    />
  );
};

export default forwardRef(Input);
